import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup, Stack } from 'react-bootstrap';
import { BondableRequest } from '../../models/bond-data';
import BondStatusUtil from '../../helper/BondStatusUtil';
import { BondStatusContent } from '../BondStatusContent';
import Utils from '../../api/Utils';

export const CurrentStatusInfo = ({ bondRequest }: { bondRequest: BondableRequest }) => {
  const filterDenialReason = (denialReason: string) => {
    if (denialReason === 'Alien in transit') {
      return 'Noncitizen in transit';
    }
    if (denialReason === 'Alien ineligible for a bond') {
      return 'Noncitizen cannot be released on bond';
    }
    if (denialReason === 'Other') {
      return 'Other (see comments for more detail)';
    }

    return denialReason;
  };

  if (BondStatusUtil.isActionNeeded(bondRequest)) {
    return <></>;
  }

  return (
    <ListGroup.Item variant='info'>
      <Stack direction='horizontal'>
        <div>
          <FontAwesomeIcon className='pe-3 fs-3' icon={faInfoCircle} color={'mediumblue'} />
        </div>
        <div>
          <div className='fw-bold'>Your Current Status</div>
          <div>
            <BondStatusContent bondStatusCode={bondRequest.statusCode.code} />
            <small className='ms-2'>
              (Status Date: {Utils.formatDisplayDate(bondRequest?.actionDateTime)})
            </small>
          </div>
          <div>
            {bondRequest?.denialReason && (
              <div className='mt-3'>
                Denial Reason: {filterDenialReason(bondRequest?.denialReason)}
                <div className='mt-1'>
                  {bondRequest.eroComments} (more detail may be provided in comments below)
                </div>
              </div>
            )}
          </div>
        </div>
      </Stack>
    </ListGroup.Item>
  );
};
