import { useState } from 'react';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Spinner, Stack } from 'react-bootstrap';
import Utils from '../../api/Utils';
import ObligorDocumentApi from '../../api/ObligorDocumentApi';
import { IBondRequestDocument } from '../../api/types';

export const I220BProgress = ({ bondI220B }: { bondI220B: IBondRequestDocument | undefined }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDocDownload = (bondDoc: IBondRequestDocument) => {
    if (bondDoc) {
      console.log('downloading form bondDoc...');
      setIsDownloading(true);
      ObligorDocumentApi.downloadBondRequestDoc(bondDoc.bondReqDocId).then((blob) => {
        var fileName = bondDoc.name;
        fileName = fileName.replace('.pdf_I323O', '.pdf'); // this is workaround for EARM file naming bug
        fileName = fileName.replace('.pdf_I391O', '.pdf'); // this is workaround for EARM file naming bug
        Utils.downloadBlob(blob, fileName);
        setIsDownloading(false);
      });
    }
  };

  if (bondI220B) {
    return (
      <ListGroup.Item className='align-items-center'>
        <Stack direction='horizontal'>
          <div>
            <FontAwesomeIcon className='pe-3 fs-3' icon={faCheckCircle} color={'green'} />
          </div>
          <div>
            <div>
              <div>
                You have successfully viewed the I220B form related to this bond payment request.
                You may download it here if needed.
              </div>
              <div className='small'>Date: {Utils.formatDisplayDate(bondI220B.createDate)}</div>
              {bondI220B.viewedDate && (
                <div className='small'>
                  Viewed Date: {Utils.formatDisplayDate(bondI220B.viewedDate)}
                </div>
              )}
            </div>
            <div className='small'>
              <Button
                className='ps-1'
                variant={'link'}
                onClick={() => handleDocDownload(bondI220B)}
              >
                <span className='small'>Download I220B</span>
                <FontAwesomeIcon className='ps-2' icon={faDownload} title='Download' />
              </Button>
              {isDownloading && (
                <span className='ps-3 small'>
                  <Spinner animation='border' role='status' size='sm' />
                  &nbsp; Downloading...
                </span>
              )}
            </div>
          </div>
        </Stack>
      </ListGroup.Item>
    );
  }

  return <></>;
};
