import { useContext, useEffect, useState } from 'react';
import { Button, Container, Spinner, Table, Form } from 'react-bootstrap';
import ObligorApi from '../api/ObligorApi';
import ObligorDocumentApi from '../api/ObligorDocumentApi';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { ICeBondsSubject, IObligor, IObligorDocument, IObligorDocumentType } from '../api/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronLeft,
  faDownload,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { CeBondsUserContext } from '../contexts/UserContext';
import LoadingPage from './util/LoadingPage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SubjectDisplay } from '../components/SubjectDisplay';
import Utils from '../api/Utils';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import ObligorEditModal from '../components/ObligorEditModal';
import UploadObligorDocModal from '../components/UploadObligorDocModal';
import FormattingUtil from '../helper/FormattingUtil';

const ObligorPage = () => {
  const ceBondsUser = useContext(CeBondsUserContext);
  const [obligor, setObligor] = useState<IObligor>();
  const [obligorDocument, setObligorDocument] = useState<IObligorDocument>();
  const [isObligorDocBlank, setIsObligorDocBlank] = useState<boolean>(false);
  const [obligorDocumentTypeList, setObligorDocumentTypeList] = useState<IObligorDocumentType[]>();
  const [isObligorBlank, setIsObligorBlank] = useState<boolean>(false);
  const [isObligorDocumentDownloading, setIsObligorDocumentDownloading] = useState<boolean>();
  const [isBondRequestSaving, setIsBondRequestSaving] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const error = useErrorContext();

  const ceBondsSubject = location.state as ICeBondsSubject;

  useEffect(() => {
    document.title = 'New Bond Payment Request - ICE CeBONDS';

    if (!obligor) {
      ObligorApi.getObligor()
        .then((cbo) => {
          if (cbo && cbo.obligorId) {
            setObligor(cbo);

            if (!obligorDocument) {
              ObligorDocumentApi.getRecentObligorDocument().then((doc) => {
                if (doc && doc.obligorDocId) {
                  setObligorDocument(doc);
                } else {
                  setIsObligorDocBlank(true);
                }
              });
            }
          } else {
            setIsObligorBlank(true);
            setIsObligorDocBlank(true);
          }
        })
        .catch((reason) => {
          console.error('get obligor error ', reason);
        });
    }
  }, [obligor, obligorDocument]);

  useEffect(() => {
    //when an obligor is added, then get the doc types for the user to add a document
    if (obligor) {
      if (!obligorDocumentTypeList) {
        ObligorDocumentApi.getObligorDocumentTypes().then((obligorDocumentTypes) =>
          setObligorDocumentTypeList(obligorDocumentTypes),
        );
      }
    }
  }, [obligor, obligorDocumentTypeList]);

  const handleSavedObligor = (savedObligor: IObligor | undefined) => {
    setObligor(savedObligor);
  };

  const handleObligorDocUploaded = (uploadedDocument: IObligorDocument | undefined) => {
    setObligorDocument(uploadedDocument);
  };

  const handleDocumentDownload = () => {
    console.log('downloading document...');
    if (obligorDocument) {
      setIsObligorDocumentDownloading(true);
      ObligorDocumentApi.downloadObligorDoc(obligorDocument.obligorDocId).then((blob) => {
        Utils.downloadBlob(blob, obligorDocument.name);
        setIsObligorDocumentDownloading(false);
      });
    } else {
      //display error message
    }
  };

  const handleSubmitBondRequest = (event: { currentTarget: any }) => {
    const form = event.currentTarget.form;

    if (form.checkValidity()) {
      if (ceBondsUser && obligor && obligorDocument) {
        // if both obligor and document exist then allow the request to be submitted
        setIsBondRequestSaving(true);
        SubjectSearchApi.SubmitBondRequest(ceBondsSubject)
          .then((cbr) => {
            // bond payment request added. redirect to status page for this bond payment request.
            if (Utils.isError(cbr)) {
              setIsBondRequestSaving(false);
              const errorResponse = cbr as IApiError;
              error.setError('Failed to submit bond payment request: ' + errorResponse.message);
            } else if ('bondRequestId' in cbr) {
              navigate('/bond-request', { state: cbr.bondRequestId });
            } else {
              setIsBondRequestSaving(false);
              error.setError('Unknown internal error');
            }
          })
          .catch((reason) => {
            setIsBondRequestSaving(false);
            error.setError('save bond payment request error ', reason);
          });
      }
    }

    setIsValidated(true);
  };

  if (
    isBondRequestSaving ||
    (!obligor && !isObligorBlank) ||
    (!obligorDocument && !isObligorDocBlank)
  ) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container className='mt-3 mb-5'>
      {/* <Trail step={1}></Trail> */}
      <h3>New Bond Payment Request</h3>
      <SubjectDisplay ceBondsSubject={ceBondsSubject} />
      <div className='mt-3'>
        <div>
          <span className='fw-bold'>Obligor Information</span>
        </div>
        {!obligor && (
          <div>
            We need your information before you can submit a bond payment request. Please provide
            your mailing address and other contact information. You will also be prompted to upload
            documentation to verify your identity.
          </div>
        )}
        {obligor && (
          <div>Please review your information before submitting this bond payment request.</div>
        )}
      </div>
      {obligor && (
        <div>
          <Table size='sm' borderless className='mb-2 obligor-table'>
            <tr>
              <td className='bg-light'>Your Status</td>
              <td>{obligor?.typeCode?.name}</td>
            </tr>
            <tr>
              <td className='bg-light'>
                {obligor?.typeCode?.code === 'Citizen' && <>SSN</>}
                {obligor?.typeCode?.code === 'LPR' && <>SSN</>}
                {obligor?.typeCode?.code === 'NonCitizen' && <>ITIN or A-Number</>}
                {(obligor?.typeCode?.code === 'NonProfit' ||
                  obligor?.typeCode?.code === 'LawFirm') && <>EIN</>}
              </td>
              <td>{obligor?.einTinNbr}</td>
            </tr>
            {obligor && obligor.name && (
              <tr>
                <td className='bg-light'>Org. Name</td>
                <td>{obligor.name}</td>
              </tr>
            )}
            <tr>
              <td className='bg-light'>Your Name</td>
              <td>
                {obligor?.lastName}, {obligor?.firstName}
              </td>
            </tr>
            <tr>
              <td className='bg-light'>Email</td>
              <td>{obligor?.personalEmail}</td>
            </tr>
            <tr>
              <td className='bg-light'>Phone</td>
              <td>{FormattingUtil.formatPhone(obligor?.phoneNbr)}</td>
            </tr>
            <tr>
              <td className='bg-light align-top'>Mailing Address</td>
              <td>
                <div className='p-0'>{obligor?.street1}</div>
                {obligor?.street2 && <div className='p-0'>{obligor?.street2}</div>}
                <div className='p-0'>
                  {obligor?.city}, {obligor?.stateCd} {FormattingUtil.formatZip(obligor?.zip)}
                </div>
              </td>
            </tr>
          </Table>
        </div>
      )}
      <ObligorEditModal
        onSave={(savedObligor) => handleSavedObligor(savedObligor)}
        obligor={obligor}
        obligorEmail={ceBondsUser?.email}
        buttonText={obligor ? 'Edit' : 'Add'}
      />
      {obligor && (
        <div>
          <div className='mt-4'>
            <span className='fw-bold'>Identity Verification Document</span>
          </div>
          {!obligorDocument && (
            <div className='mb-1'>
              <div className='mb-2'>
                <FontAwesomeIcon icon={faExclamationCircle} /> Please provide one document that can
                be used to verify your identity. You must upload a document for ICE review. Note:
                only pdf files are accepted.
              </div>
              <div>
                <UploadObligorDocModal
                  onObligorDocUploaded={(uploadedDocument) =>
                    handleObligorDocUploaded(uploadedDocument)
                  }
                  obligorTypeCode={obligor.typeCode?.code}
                  buttonText='Add Document'
                />
              </div>
            </div>
          )}
          {obligorDocument && (
            <div className='mb-1'>
              <div className='mb-2'>
                <FontAwesomeIcon icon={faCheckCircle} /> You have uploaded the following document
                for review.
              </div>
              <div className='mb-2'>
                <span>
                  {obligorDocument.name} ({obligorDocument.typeCode.description})
                </span>
                <Button className='ms-2' variant='link' size='sm' onClick={handleDocumentDownload}>
                  Download <FontAwesomeIcon icon={faDownload} />
                </Button>
                <UploadObligorDocModal
                  onObligorDocUploaded={(uploadedDocument) =>
                    handleObligorDocUploaded(uploadedDocument)
                  }
                  obligorTypeCode={obligor.typeCode?.code}
                  buttonText='Replace'
                />
              </div>
              <div>
                {isObligorDocumentDownloading && (
                  <div>
                    <Spinner animation='border' role='status' size='sm' />
                    &nbsp; Please Wait...
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <Form noValidate validated={isValidated}>
        {obligor && obligorDocument && (
          <div className='mt-3'>
            <div className='fw-bold'>Consent to Receive Electronic Notices</div>
            <div>
              By utilizing the CeBONDS system you agree to allow Immigration and Customs Enforcement
              (ICE) to communicate with you electronically and you consent to electronic delivery of
              bond notices and documents from ICE via the CeBONDS system and e-mail. You also agree
              to check your CeBONDS account, alerts, and messages, and the e-mail account associated
              with your CeBONDS account (including spam and bulk folders) on a reasonably regular
              basis to stay apprised of important notices and information about your account and the
              bond you posted. You undertake to update your email in the event you change your email
              address.
            </div>
            <Form.Check>
              <Form.Check.Input type='checkbox' required />
              <Form.Check.Label>I agree</Form.Check.Label>
              <Form.Control.Feedback className='fw-bold' type='invalid'>
                You must agree to the above terms to submit a bond payment request.
              </Form.Control.Feedback>
            </Form.Check>
          </div>
        )}
        <div className='mt-5'>
          <Link to='/subject'>
            <Button className='me-3' variant='secondary'>
              <FontAwesomeIcon icon={faChevronLeft} className='me-1' /> Back
            </Button>
          </Link>
          {obligor && obligorDocument && (
            <span>
              <Button
                className='me-3'
                variant='primary'
                onClick={handleSubmitBondRequest}
                disabled={isBondRequestSaving}
              >
                Submit Bond Payment Request
              </Button>
            </span>
          )}
        </div>
      </Form>
    </Container>
  );
};

export default ObligorPage;
