import { IBondRequestDocument } from '../api/types';

class BondDocUtil {
  static isNotice(bondRequestDoc: IBondRequestDocument): boolean {
    return (
      bondRequestDoc.typeCode.code === 'I323' ||
      bondRequestDoc.typeCode.code === 'I340' ||
      bondRequestDoc.typeCode.code === 'I391' ||
      bondRequestDoc.typeCode.code === 'MTR' ||
      bondRequestDoc.typeCode.code === 'MTG'
    );
  }

  static isNoticeActionNeeded(bondRequestDoc: IBondRequestDocument): boolean {
    if (this.isNotice(bondRequestDoc)) {
      if (bondRequestDoc.viewedDate) {
        return false;
      }

      return true;
    }

    return false;
  }

  static getNoticeText = (bondNotice: IBondRequestDocument) => {
    if (bondNotice?.typeCode.code === 'I323') {
      return 'NOTICE - IMMIGRATION BOND BREACHED (form I-323).';
    }
    if (bondNotice?.typeCode.code === 'I340') {
      return 'NOTICE - OBLIGOR TO DELIVER ALIEN (form I-340).';
    }
    if (bondNotice?.typeCode.code === 'I391') {
      return 'NOTICE - IMMIGRATION BOND CANCELLED (form I-391).';
    }
    if (bondNotice?.typeCode.code === 'MTR') {
      return 'NOTICE - BOND BREACH RECONSIDERATION DECISION (MTR).';
    }
    if (bondNotice?.typeCode.code === 'MTG') {
      return 'NOTICE - BOND MITIGATION (MTG).';
    }

    return bondNotice?.typeCode.code;
  };

  static getMostRecentNotice(
    bondNoticeList: IBondRequestDocument[] | undefined,
  ): IBondRequestDocument | undefined {
    var mostRecentNotice: IBondRequestDocument | undefined;
    if (bondNoticeList) {
      // get the most recent notice
      bondNoticeList.forEach((notice) => {
        if (mostRecentNotice === undefined) {
          mostRecentNotice = notice;
        } else if (notice.bondReqDocId > mostRecentNotice.bondReqDocId) {
          mostRecentNotice = notice;
        }
      });
    }

    return mostRecentNotice;
  }
}

export default BondDocUtil;
