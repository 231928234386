import { IAcsCandidate } from '../models/acsCadidate';
import Api from './Api';

class AcsApi {
  static getAcsCandidate(
    street1: string,
    street2: string,
    city: string,
    statecode: string,
    zipcode: string,
  ): Promise<IAcsCandidate> {
    const secondary = street2 || ''; // Replace null
    const endpoint =
      '/acs/getcandidate?street=' +
      encodeURIComponent(street1) +
      '&secondary=' +
      encodeURIComponent(secondary) +
      '&city=' +
      encodeURIComponent(city) +
      '&state=' +
      encodeURIComponent(statecode) +
      '&zipcode=' +
      encodeURIComponent(zipcode);

    return Api.GET_raw(endpoint).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        if (response.status === 404) {
          return undefined;
        } else {
          throw new Error('unexpected status:' + response.status);
        }
      }
    });
  }
}

export default AcsApi;
