import { useState } from 'react';
import { Link } from 'react-router-dom';
import { faExclamationCircle, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Stack } from 'react-bootstrap';
import { BondPayment, BondableRequest } from '../../models/bond-data';
import { BondStatusEnum } from '../../models/status';
import { BondStatusContent } from '../BondStatusContent';
import SubjectSearchApi from '../../api/SubjectSearchApi';
import LoadingPage from '../../pages/util/LoadingPage';

export interface ISubmitContractAction {
  onSubmitted?: (submittedBondRequest: BondableRequest) => void;
  bondRequest: BondableRequest;
  bondPayment: BondPayment | undefined;
}

const SubmitContractAction = ({ onSubmitted, bondRequest, bondPayment }: ISubmitContractAction) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    // both contract and payment steps are complete so allow the submit to EARM
    if (onSubmitted && bondRequest) {
      setIsSubmitting(true);
      SubjectSearchApi.SubmitContractAndPayment(bondRequest.bondRequestId)
        .then((cbr) => {
          // request now submitted for ICE review 2.
          onSubmitted(cbr);
          setIsSubmitting(false);
        })
        .catch((reason) => {
          setIsSubmitting(false);
          console.log('submit bond contract/payment error ', reason);
          //todo: display error message
        });
    }
  };

  if (isSubmitting) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  if (bondRequest.statusCode.code === BondStatusEnum.BOND_PAYMENT_UPLOADED) {
    // The obligor just uploaded payment proof.
    return (
      <>
        <ListGroup.Item variant='warning'>
          <Stack direction='horizontal'>
            <div>
              <FontAwesomeIcon className='pe-3 fs-2' icon={faExclamationCircle} color={'orange'} />
            </div>
            <div>
              <div>
                <span className='fw-bold'>Action Required: </span>
                <BondStatusContent bondStatusCode={BondStatusEnum.BOND_PAYMENT_UPLOADED} />
              </div>
              <div className='mt-2 mb-2'>
                <Button className='me-3' onClick={handleSubmit} disabled={isSubmitting}>
                  Submit Contract and Payment <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction='horizontal'>
            <div>
              <small>
                <div className='fw-bold'>Optional Actions:</div>
                <div>
                  If you need to make changes to your contract or payment receipt, use the buttons
                  below.
                </div>
                <Link to='/i352' state={bondRequest}>
                  <Button className='m-2' size='sm' variant='secondary'>
                    Re-Sign Contract (optional)
                  </Button>
                </Link>
                <Link to='/bond-payment' state={bondRequest}>
                  <Button className='m-2' size='sm' variant='secondary'>
                    Re-Upload Payment Receipt (optional)
                  </Button>
                </Link>
              </small>
            </div>
          </Stack>
        </ListGroup.Item>
      </>
    );
  }

  return <></>;
};

export default SubmitContractAction;
