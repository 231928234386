import { Link } from 'react-router-dom';
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Stack } from 'react-bootstrap';
import Utils from '../../api/Utils';
import { IBondRequestDocument } from '../../api/types';
import BondDocUtil from '../../helper/BondDocUtil';

export const NoticeAction = ({
  bondNoticeList,
}: {
  bondNoticeList: IBondRequestDocument[] | undefined;
}) => {
  var mostRecentNotice = BondDocUtil.getMostRecentNotice(bondNoticeList);

  // if last notice and it is not acked, then notice needs action
  if (mostRecentNotice && BondDocUtil.isNoticeActionNeeded(mostRecentNotice)) {
    return (
      <>
        <ListGroup.Item variant='warning'>
          <Stack direction='horizontal'>
            <div>
              <FontAwesomeIcon className='pe-3 fs-2' icon={faExclamationCircle} color={'orange'} />
            </div>
            <div>
              <div>
                <div className='fw-bold'>Action Required: </div>
                <div>
                  {BondDocUtil.getNoticeText(mostRecentNotice)} Please view the bond notice form
                  provided to you here. Click the View Notice button below.
                </div>
                <div className='small'>
                  Notice Date: {Utils.formatDisplayDate(mostRecentNotice.createDate)}
                </div>
              </div>
              <div className='mt-2 mb-2'>
                <Link to='/notice' state={mostRecentNotice}>
                  <Button>
                    View Notice
                    <FontAwesomeIcon className='ps-2' icon={faChevronRight} />
                  </Button>
                </Link>
              </div>
            </div>
          </Stack>
        </ListGroup.Item>
      </>
    );
  }

  return <></>;
};
