import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import ObligorI352Summary from './ObligorI352Summary';
import { ICommonAddress, INonCitizenAddress, IObligor } from '../../api/types';
import ObligorApi from '../../api/ObligorApi';
import LoadingPage from '../util/LoadingPage';
import { BondableRequest } from '../../models/bond-data';
import USStateSelect from '../../components/form/USStateSelect';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SubjectSearchApi from '../../api/SubjectSearchApi';
import { useErrorContext } from '../../contexts/ErrorContext';
import AcsFeedbackModal from '../../components/AcsFeedbackModal';
import { IAcsCandidate } from '../../models/acsCadidate';

export interface IBondContractDataEntryProps {
  bondRequest?: BondableRequest;
}

export interface IBondFormEntryFields {
  [key: string]: any;
}

const BondContractDataEntry = ({ bondRequest: propBondRequest }: IBondContractDataEntryProps) => {
  const [obligor, setObligor] = useState<IObligor>();
  const [formValid, setFormValid] = useState(false);
  const [address1Input, setAddress1Input] = useState<string>('');
  const [address2Input, setAddress2Input] = useState<string>('');
  const [address3Input, setAddress3Input] = useState<string>('');
  const [cityInput, setCityInput] = useState<string>('');
  const [stateInput, setStateInput] = useState<string>('');
  const [zipCodeInput, setZipCodeInput] = useState<string>('');
  const [phoneNumberInput, setPhoneNumberInput] = useState<string>('');
  const [isBusy, setIsBusy] = useState(false);
  const [addressToVerify, setAddressToVerify] = useState<ICommonAddress>();
  const error = useErrorContext();

  const navigate = useNavigate();
  const location = useLocation();

  const bondRequest = propBondRequest || (location.state as BondableRequest);

  useEffect(() => {
    document.title = 'I-352 Input - ICE CeBONDS';
    error.clearError(); // clears error banner on page load

    if (!obligor) {
      ObligorApi.getObligor().then((value) => setObligor(value));
    }

    if (bondRequest) {
      // set default values for input form
      setAddress1Input(bondRequest.address1);
      setAddress2Input(bondRequest.address2);
      setAddress3Input(bondRequest.address3);
      setCityInput(bondRequest.residenceCity);
      setStateInput(bondRequest.residenceState);
      setZipCodeInput(bondRequest.residenceZip);
      setPhoneNumberInput(bondRequest.residencePhone);
    }
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const inputForm = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setFormValid(false);
    if (inputForm.checkValidity()) {
      const addressToVerify: ICommonAddress = {
        street1: address1Input,
        street2: address2Input,
        street3: '',
        city: cityInput,
        stateCd: stateInput,
        zip: zipCodeInput.replaceAll(/[^0-9]/g, ''),
        countryCd: '',
      };
      setAddressToVerify(addressToVerify); // This will trigger the ACS Feedback to verify the address
    } else {
      setFormValid(true);
    }
  };

  const handleAcsFeedbackAccept = (acceptedAcsCandidate?: IAcsCandidate) => {
    // We have a good address. Save it.
    if (phoneNumberInput && acceptedAcsCandidate) {
      setIsBusy(true);

      const nonCitizenAddress: INonCitizenAddress = {
        address1: acceptedAcsCandidate.delivery_line_1,
        address2: '',
        address3: '',
        residenceCity: acceptedAcsCandidate.components.city_name,
        residenceState: acceptedAcsCandidate.components.state_abbreviation,
        residenceZip:
          acceptedAcsCandidate.components.zipcode + acceptedAcsCandidate.components.plus4_code,
        residencePhone: phoneNumberInput.replaceAll(/[^0-9]/g, ''),
      };

      SubjectSearchApi.saveNCAddress(bondRequest?.bondRequestId, nonCitizenAddress).then(
        (response) => {
          if (response.status === 200) {
            navigate('/i352/sign', { state: bondRequest.bondRequestId });
          } else {
            error.setError('Unexpected error encountered. [snca]');
            setIsBusy(false);
          }
        },
      );
    }
  };

  const handleAcsFeedbackReject = () => {
    // Do nothing
  };

  const renderAddressInput = () => {
    return (
      <>
        <Form.Group className='mb-2'>
          <Form.Label>Home address</Form.Label>
          <Form.Control
            type='text'
            defaultValue={address1Input}
            placeholder='Address Line 1'
            required
            maxLength={40}
            onChange={(event) => setAddress1Input(event.target.value)}
          />
          <Form.Control.Feedback type='invalid'>Address is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Control
            type='text'
            defaultValue={address2Input}
            placeholder='Address Line 2'
            maxLength={40}
            onChange={(event) => setAddress2Input(event.target.value)}
          />
        </Form.Group>
        {address3Input && (
          <Form.Group className='mb-2'>
            <Form.Control
              type='text'
              defaultValue={address3Input}
              placeholder='Address Line 3'
              maxLength={40}
              onChange={(event) => setAddress3Input(event.target.value)}
            />
          </Form.Group>
        )}
        <Form.Group className='mb-2'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            defaultValue={cityInput}
            required
            maxLength={23}
            onChange={(event) => setCityInput(event.target.value)}
          />
          <Form.Control.Feedback type='invalid'>City is required</Form.Control.Feedback>
        </Form.Group>
        <Row className='mb-3'>
          <Form.Group as={Col} sm='8'>
            <USStateSelect
              defaultValue={stateInput}
              required
              invalidFeedback={'State is required'}
              onChange={(state) => setStateInput(state.abbreviation)}
              id={'stateInput'}
            />
          </Form.Group>
          <Form.Group as={Col} sm='4' controlId='validationCustom05'>
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type='text'
              defaultValue={zipCodeInput}
              required
              pattern='^\d{5}([\-])?(\d{4})?$'
              onChange={(event) => setZipCodeInput(event.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Valid zip is required</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className='mb-2'>
          <Form.Label>Phone</Form.Label>
          <InputGroup>
            <InputGroup.Text id='basic-addon1'>+1</InputGroup.Text>
            <Form.Control
              type='text'
              defaultValue={phoneNumberInput}
              required
              pattern='^\(?\d{3}\)?[\s.\-]?\d{3}[\s.\-]?\d{4}$'
              onChange={(event) => setPhoneNumberInput(event.target.value)}
            />
            <Form.Control.Feedback type='invalid'>Valid phone is required</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </>
    );
  };

  const renderBondRequest = () => {
    return (
      <>
        <div>
          <h6>Noncitizen to reside at</h6>
          <div>
            <div>{renderAddressInput()}</div>
          </div>
        </div>
      </>
    );
  };

  if (isBusy || !obligor) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container>
      <Form noValidate={true} className={'w-100'} validated={formValid} onSubmit={handleSubmit}>
        <ObligorI352Summary obligor={obligor} bondRequest={bondRequest} setObligor={setObligor} />
        <hr />
        {renderBondRequest()}
        <hr />
        <div>
          <Link to='/bond-request' state={bondRequest.bondRequestId}>
            <Button variant='secondary' className='me-3'>
              Cancel
            </Button>
          </Link>
          <Button variant='primary' type={'submit'}>
            Next <FontAwesomeIcon icon={faChevronRight} className='ms-1' />
          </Button>
        </div>
      </Form>
      <AcsFeedbackModal
        addressToVerify={addressToVerify}
        checkForResidential={true}
        onAccept={(acceptedAcsCanidate) => handleAcsFeedbackAccept(acceptedAcsCanidate)}
        onReject={() => handleAcsFeedbackReject()}
      />
    </Container>
  );
};

export default BondContractDataEntry;
