import React, { useEffect, useState } from 'react';
import './assets/styles/app.scss';
import { Footer } from './components/Footer';
import styles from './assets/styles/app.scss';
import Header from './components/layout/Header';
import { Container } from 'react-bootstrap';
import { CeBondsUserContext } from './contexts/UserContext';
import { Route, Routes } from 'react-router-dom';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import ObligorPage from './pages/ObligorPage';
import { HelpAndSupport } from './pages/HelpAndSupport';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { SearchForm } from './pages/SearchForm';
import WithdrawRequestPage from './pages/WithdrawRequestPage';
import { ICeBondsUser } from './api/types';
import UserApi from './api/UserApi';
import { LandingPage } from './pages/LandingPage';
import { BondStatusContainer } from './pages/BondStatusContainer';
import LoadingPage from './pages/util/LoadingPage';
import { RequiredAuth } from './components/RequiredAuth';
import { BondPaymentUpload } from './pages/BondPayment';
import BondContractDataEntry from './pages/i352/BondContractDataEntry';
import SignI352 from './pages/i352/SignI352';
import { BondBundle } from './pages/BondBundle';
import { ErrorContext, IError } from './contexts/ErrorContext';
import ErrorBanner from './components/ErrorBanner';
import NotFound from './pages/errors/NotFound';
import UnauthorizedPage from './pages/errors/UnauthorizedPage';
import { BondRequestPage } from './pages/BondRequestPage';
import NoticePage from './pages/NoticePage';
import { ReviewAddressPage } from './pages/ReviewAddressPage';

const App = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [cebondsUser, setCeBondsUser] = useState<ICeBondsUser>();
  const [error, setError] = useState<IError>();

  useEffect(() => {
    if (authState?.accessToken?.claims?.uid && !cebondsUser) {
      UserApi.getCurrentUserInfo().then((userInfo) => {
        if (userInfo) {
          setCeBondsUser(userInfo);
        }
      });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (cebondsUser) {
      if (authState?.idToken?.claims && authState.idToken.claims.email) {
        const { email } = authState.idToken.claims;
        if (email !== cebondsUser.email) {
          // save new okta email for current user
          UserApi.saveEmail(email).then((savedUser) => {
            setCeBondsUser(savedUser);
          });
        }
      }
    }
  }, [cebondsUser]);

  if (authState === null && !oktaAuth.isLoginRedirect()) {
    return <LoadingPage message={'Loading authentication...'} />;
  }

  const secure = (element: React.ReactElement): React.ReactElement => (
    <RequiredAuth>{element}</RequiredAuth>
  );

  const pages = () => (
    <Routes>
      <Route path='/login/callback' element={<LoginCallback />} />
      <Route path='/obligor' element={secure(<ObligorPage />)} />
      <Route path='/help' element={secure(<HelpAndSupport />)} />
      <Route path='/privacy-policy' element={secure(<PrivacyPolicy />)} />
      <Route path='/bond-status-container' element={secure(<BondStatusContainer />)} />
      <Route path='/subject' element={secure(<SearchForm />)} />
      <Route path='/bond-payment' element={secure(<BondPaymentUpload />)} />
      <Route path='/bond-bundle' element={secure(<BondBundle />)} />
      <Route path='/bond-request' element={secure(<BondRequestPage />)} />
      <Route path='/withdraw-bond-request' element={secure(<WithdrawRequestPage />)} />
      <Route path='/notice' element={secure(<NoticePage />)} />
      <Route path='/reviewaddress' element={secure(<ReviewAddressPage />)} />

      <Route path='/i352' element={secure(<BondContractDataEntry />)} />
      <Route path='/i352/sign' element={secure(<SignI352 />)} />

      <Route path='/error/not-found' element={secure(<NotFound />)} />
      <Route path='/error/unauthorized' element={secure(<UnauthorizedPage />)} />

      <Route path='/' element={<LandingPage />} />
    </Routes>
  );

  const updateError = (message: string, debug?: any) => setError({ message });
  const clearError = () => setError(undefined);

  return (
    <Container fluid className={styles.outerContainer} style={{ padding: 0 }}>
      <CeBondsUserContext.Provider value={cebondsUser}>
        <Header />
        <ErrorBanner error={error} onDismiss={clearError} />
        <ErrorContext.Provider value={{ error, setError: updateError, clearError }}>
          <div id='cebonds-page-container' style={{ marginBottom: '100px' }}>
            {pages()}
          </div>
        </ErrorContext.Provider>
        <Footer />
      </CeBondsUserContext.Provider>
    </Container>
  );
};

export default App;
