import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import { IAcsCandidate } from '../models/acsCadidate';
import { ICommonAddress } from '../api/types';
import { useEffect, useState } from 'react';
import AcsApi from '../api/AcsApi';
import { useErrorContext } from '../contexts/ErrorContext';

export interface IAcsFeedbackModal {
  addressToVerify?: ICommonAddress;
  checkForResidential?: boolean;
  onAccept: (acceptedAcsCandidate?: IAcsCandidate) => void;
  onReject: () => void; // aka onReject
}

const AcsFeedbackModal = ({
  addressToVerify,
  checkForResidential,
  onAccept,
  onReject,
}: IAcsFeedbackModal) => {
  const [isAddressVerifying, setIsAddressVerifying] = useState<boolean>(false);
  const [acsCandidateResult, setAcsCandidateResult] = useState<IAcsCandidate>();
  const [isCandidateNotFound, setIsCandidateNotFound] = useState<boolean>(false);
  const [isCandidateAcceptable, setIsCandidateAcceptable] = useState<boolean>(false);
  const [isCandidateNotAcceptable, setIsCandidateNotAcceptable] = useState<boolean>(false);
  const [isResidentialNeeded, setIsResidentialNeeded] = useState<boolean>(false);
  const error = useErrorContext();

  useEffect(() => {
    setIsAddressVerifying(false);
    if (addressToVerify) {
      setIsAddressVerifying(true);
      AcsApi.getAcsCandidate(
        addressToVerify.street1,
        addressToVerify.street2,
        addressToVerify.city,
        addressToVerify.stateCd,
        addressToVerify.zip,
      )
        .then((acsCandidate) => {
          setIsAddressVerifying(false);
          setAcsCandidateResult(acsCandidate);
          if (isAcsCandidateGoodMatch(acsCandidate)) {
            handleAutoAccept(acsCandidate);
          } else {
            showFeedbackOptions(acsCandidate);
          }
        })
        .catch((reason) => {
          setIsAddressVerifying(false);
          error.setError('getAcs: ' + reason);
        });
    }
  }, [addressToVerify]);

  const isAcsCandidateGoodMatch = (acsCandidate: IAcsCandidate | undefined) => {
    let isGoodMatch = false;

    if (acsCandidate) {
      // Address found
      isGoodMatch = ignoreAcsFootnote(acsCandidate);
    } else {
      // Address not found
      isGoodMatch = false;
    }

    if (isGoodMatch && checkForResidential) {
      isGoodMatch = getIsAddressResidential(acsCandidate);
    }

    return isGoodMatch;
  };

  const getIsAddressResidential = (acsCandidate: IAcsCandidate | undefined) => {
    return acsCandidate?.metadata.rdi === 'Residential';
  };

  const ignoreAcsFootnote = (acsCandidate: IAcsCandidate | undefined) => {
    return (
      acsCandidate?.analysis.footnotes === undefined ||
      acsCandidate?.analysis.footnotes === null ||
      acsCandidate?.analysis.footnotes === 'N#' || // Fixed abbreviations
      acsCandidate?.analysis.footnotes === 'E#' // Same ZIP for multiple
    );
  };

  const showFeedbackOptions = (acsCandidate: IAcsCandidate | undefined) => {
    if (acsCandidate === undefined) {
      setIsCandidateNotFound(true); // show the not found modal
    } else if (
      acsCandidate?.analysis.footnotes?.includes('S#') || // Unrecognized secondary address
      acsCandidate?.analysis.footnotes?.includes('H#') || // Missing secondary number
      acsCandidate?.analysis.footnotes?.includes('V#')
    ) {
      // Unverifiable city/state
      setIsCandidateNotAcceptable(true); // show the not acceptable modal
    } else {
      setIsCandidateAcceptable(true); // show the acceptable modal
      if (checkForResidential && !getIsAddressResidential(acsCandidate)) {
        setIsResidentialNeeded(true); // include residential is needed messaging
      }
    }
  };

  const hideFeedbackOptions = () => {
    // Hides all modals
    setIsAddressVerifying(false);
    setIsCandidateNotFound(false);
    setIsCandidateNotAcceptable(false);
    setIsCandidateAcceptable(false);
  };

  const getFeedbackMessage = () => {
    let feedbackMessage = '';

    if (acsCandidateResult?.analysis.footnotes?.includes('S#')) {
      feedbackMessage += 'The secondary information (apartment, suite, etc.) was not recognized. ';
    }
    if (acsCandidateResult?.analysis.footnotes?.includes('H#')) {
      feedbackMessage +=
        'The address as submitted is missing a secondary number (apartment, suite, etc.). ';
    }
    if (acsCandidateResult?.analysis.footnotes?.includes('V#')) {
      feedbackMessage +=
        'The city and state in the submitted address could not be verified as corresponding to the given 5-digit ZIP Code. ';
    }

    return feedbackMessage;
  };

  const handleAutoAccept = (acsCandidate: IAcsCandidate) => {
    hideFeedbackOptions();
    onAccept(acsCandidate);
  };

  const handleAccept = () => {
    hideFeedbackOptions();
    onAccept(acsCandidateResult);
  };

  const handleReject = () => {
    hideFeedbackOptions();
    onReject();
  };

  if (addressToVerify) {
    return (
      <>
        <Modal size='sm' show={isAddressVerifying}>
          <Modal.Body>
            <div className='m-3'>
              <Spinner animation='border' role='status' className='me-2' />
              <span>Verifying Address...</span>
            </div>
          </Modal.Body>
        </Modal>
        <Modal size='sm' show={isCandidateNotFound}>
          <Modal.Body>
            <Alert variant='danger' className='mb-3'>
              <div>
                The address you entered was not found. Please correct the address and try again.
              </div>
            </Alert>
            <div>
              <Button variant='secondary' size='sm' onClick={handleReject}>
                Edit Address
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal size='sm' show={isCandidateNotAcceptable}>
          <Modal.Body>
            <div>
              <Alert variant='danger'>
                <div className='mb-2'>
                  The address you entered was found. However, the US Postal Service found the
                  following issues. Please review the issues and edit your address.
                </div>
                <div className='fw-bold'>{getFeedbackMessage()}</div>
              </Alert>
              <div className='mt-3'>
                <Button variant='secondary' size='sm' onClick={handleReject}>
                  Edit Address
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal size='sm' show={isCandidateAcceptable}>
          <Modal.Body>
            <div>
              {!isResidentialNeeded && (
                <Alert variant='secondary' className='mb-3'>
                  The address you entered was found. However, the US Postal Service has provided a
                  more standard or accurate address. Please review the revised address. You may
                  continue with this address or edit the address you entered.
                </Alert>
              )}
              {isResidentialNeeded && (
                <Alert variant='warning'>
                  <span className='fw-bold'>Important: </span>The US Postal Service indicates this
                  address is not residential. A residential address is needed. If you are certain
                  that the address is a residence, shelter, or hotel, then you may use this address.
                  Otherwise, edit the address you entered.
                </Alert>
              )}
              <Alert variant='info' className='mb-3'>
                <div className='fw-bold'>Address:</div>
                <div>
                  {acsCandidateResult?.delivery_line_1}
                  <br />
                  {acsCandidateResult?.last_line}
                </div>
                <div className='pt-2'>
                  <Button variant='secondary' size='sm' onClick={handleAccept}>
                    Use This Address
                  </Button>
                </div>
              </Alert>
              <div>
                <Button variant='secondary' size='sm' onClick={handleReject}>
                  Edit Address
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return <></>; // return nothing if there is no address to verify
};

export default AcsFeedbackModal;
