import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from './util/LoadingPage';
import ObligorDocumentApi from '../api/ObligorDocumentApi';
import { IBondRequestDocument } from '../api/types';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoticePage = () => {
  const location = useLocation();

  const bondNotice = location.state as IBondRequestDocument;

  const [loadingFile, setLoadingFile] = useState(false);
  const [objectURL, setObjectURL] = useState<string>();

  useEffect(() => {
    document.title = 'Bond Notice - ICE CeBONDS';

    if (bondNotice) {
      setLoadingFile(true);
      ObligorDocumentApi.downloadBondRequestDoc(bondNotice.bondReqDocId).then((blob) => {
        setObjectURL(URL.createObjectURL(blob));
        setLoadingFile(false);
      });
    }
  }, [bondNotice]);

  useEffect(() => {
    if (objectURL) {
      ObligorDocumentApi.documentViewed(bondNotice.bondReqDocId);
    }
  }, [objectURL]);

  if (loadingFile) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <div>
      <div className='m-3'>
        <h3>IMPORTANT NOTICE</h3>
        <div>Please review the entire notice below.</div>
      </div>
      <div>
        <iframe className='w-100 min-vh-100' src={objectURL} title='Notice PDF'></iframe>
      </div>
      <div className='m-3'>
        <Link to='/bond-request' state={bondNotice.bondRequestId}>
          <Button variant='secondary' className='me-2'>
            <FontAwesomeIcon icon={faChevronLeft} className='me-1' />
            Done
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NoticePage;
