import { BondableRequest } from '../models/bond-data';
import { BondPayment } from '../models/bond-data';
import Api, { NAMESPACE } from './Api';

class PaymentApi {
  static saveBondPayment(bondRequestId: number, file: FormData): Promise<BondPayment> {
    const endpoint = '/payment/paymentreceipt/add?bondRequestId=' + bondRequestId;
    return Api.POST_file(endpoint, file).then((response) => response as BondPayment);
  }

  static skipBondPayment(bondRequestId: number): Promise<BondableRequest> {
    return Api.GET('/payment/receipt/skip/' + bondRequestId).then(
      (response) => response as BondableRequest,
    );
  }

  static getBondPayment(bondRequestId: number): Promise<BondPayment> {
    return fetch(NAMESPACE + '/payment/' + bondRequestId, {
      headers: Api._getAuthHeader(),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    });
  }

  static downloadBondPaymentFile(bondRequestId: number): Promise<Blob> {
    return Api.GET_file('/payment/receipt/download/' + bondRequestId).catch((e) => undefined);
  }
}

export default PaymentApi;
