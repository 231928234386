import { useState } from 'react';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Spinner, Stack } from 'react-bootstrap';
import Utils from '../../api/Utils';
import ObligorDocumentApi from '../../api/ObligorDocumentApi';
import { IBondRequestDocument } from '../../api/types';
import { BondableRequest } from '../../models/bond-data';

export const ContractProgress = ({
  bondContract,
  bondRequest,
}: {
  bondContract: IBondRequestDocument | undefined;
  bondRequest: BondableRequest | undefined;
}) => {
  const [isDocDownloading, setIsDocDownloading] = useState<boolean>(false);

  const getSigningDate = (bondContract: IBondRequestDocument) => {
    if (bondContract.updateDate) {
      return Utils.formatDisplayDate(bondContract.updateDate);
    }

    return Utils.formatDisplayDate(bondContract.createDate);
  };

  const handleDocDownload = () => {
    if (bondContract) {
      console.log('downloading bond bondContract doc...');
      setIsDocDownloading(true);
      ObligorDocumentApi.downloadBondRequestDoc(bondContract.bondReqDocId).then((blob) => {
        Utils.downloadBlob(blob, bondContract.name);
        setIsDocDownloading(false);
      });
    }
  };

  if (bondContract && bondRequest) {
    return (
      <ListGroup.Item className='align-items-center'>
        <Stack direction='horizontal'>
          <div>
            <FontAwesomeIcon className='pe-3 fs-3' icon={faCheckCircle} color={'green'} />
          </div>
          <div>
            <div>
              You signed your I-352 bond contract.
              <span className='small ms-2'>({getSigningDate(bondContract)})</span>
            </div>
            <div className='small'>
              <Button className='ps-1' variant={'link'} onClick={() => handleDocDownload()}>
                <span className='small'>Download Contract (I-352)</span>{' '}
                <FontAwesomeIcon className='ps-2' icon={faDownload} title='Download' />
              </Button>
              {isDocDownloading && (
                <span className='ps-3'>
                  <Spinner animation='border' role='status' size='sm' />
                  &nbsp; Please Wait...
                </span>
              )}
            </div>
          </div>
        </Stack>
      </ListGroup.Item>
    );
  }

  return <></>;
};
