import Api, { NAMESPACE } from './Api';
import { IBondRequestDocument, IObligorDocument, IObligorDocumentType } from './types';

class ObligorDocumentApi {
  static getRecentObligorDocument(): Promise<IObligorDocument> {
    return Api.GET('/document/obligor/recentdocument/').catch((e) => undefined);
  }

  static saveObligorDocument(documentTypeCode: string, file: FormData): Promise<IObligorDocument> {
    const endpoint = '/document/obligor/add?documentTypeCd=' + documentTypeCode;
    return Api.POST_file(endpoint, file).then((response) => response as IObligorDocument);
  }

  static getObligorDocumentTypes(): Promise<IObligorDocumentType[]> {
    return Api.GET('/document/obligor/typecodes').then(
      (response) => response as IObligorDocumentType[],
    );
  }

  static addBondRequestObligorDoc(
    bondRequestId: number,
    documentTypeCode: string,
    file: FormData,
  ): Promise<IBondRequestDocument> {
    const endpoint =
      '/document/bondobligor/add?bondRequestId=' +
      bondRequestId +
      '&documentTypeCd=' +
      documentTypeCode +
      '&saveForFuture=true';
    return Api.POST_file(endpoint, file).then((response) => response as IBondRequestDocument);
  }

  static downloadObligorDoc(obligorDocumentId: number): Promise<Blob> {
    return Api.GET_file('/document/obligordoc/download/' + obligorDocumentId).then(
      (value) => value as Blob,
    );
  }

  static downloadBondRequestDoc(bondDocId: number): Promise<Blob> {
    return Api.GET_file('/document/bondrequestdoc/download/' + bondDocId).then(
      (value) => value as Blob,
    );
  }

  static getBondDocumentList(bondRequestId: number): Promise<IBondRequestDocument[]> {
    return Api.GET('/document/bondrequest/' + bondRequestId).then(
      (value) => value as IBondRequestDocument[],
    );
  }

  static documentViewed(bondDocId: number): Promise<IBondRequestDocument> {
    return Api.GET(`/document/documentViewed/${bondDocId}`).then(
      (value) => value as IBondRequestDocument,
    );
  }

  static documentAcked(bondDocId: number): Promise<IBondRequestDocument> {
    return Api.GET(`/document/documentAcked/${bondDocId}`).then(
      (value) => value as IBondRequestDocument,
    );
  }

  static previewI352Doc(bondRequestId: number): Promise<Blob> {
    return Api.GET_file('/document/previewI352/' + bondRequestId).then((value) => value as Blob);
  }

  static signI352Doc(bondRequestId: number, signatureImage: string): Promise<IBondRequestDocument> {
    const endpoint = '/document/signI352/' + bondRequestId;
    return Api.POST_DIRECT(NAMESPACE, endpoint, signatureImage).then(
      (response) => response as IBondRequestDocument,
    );
  }

  static payInstructionsDoc(bondRequestId: number): Promise<Blob> {
    return Api.GET_file('/document/payinstructions/' + bondRequestId).then(
      (value) => value as Blob,
    );
  }
}

export default ObligorDocumentApi;
