import React, { useEffect, useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ICeBondsSubject } from '../api/types';
import { BondableRequest, BondPayment } from '../models/bond-data';
import { SubjectDisplay } from '../components/SubjectDisplay';
import PaymentApi from '../api/PaymentApi';
import LoadingPage from './util/LoadingPage';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { BondStatusEnum } from '../models/status';

export const BondBundle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bondableRequest = location.state as BondableRequest;

  const [ceBondsSubject, setCeBondsSubject] = useState<ICeBondsSubject>();
  const [bondPayment, setBondPayment] = useState<BondPayment>();
  const [isPaymentLoading, setIsPaymentLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isContractStepComplete, setIsContractStepComplete] = useState(false);

  useEffect(() => {
    document.title = 'Submit Bond Contract and Payment - ICE CeBONDS';

    if (bondableRequest) {
      setCeBondsSubject({
        firstName: bondableRequest.firstName,
        lastName: bondableRequest.lastName,
        aNumber: bondableRequest.anumber,
      });

      PaymentApi.getBondPayment(bondableRequest.bondRequestId).then((proofDoc) => {
        if (proofDoc && 'paymentId' in proofDoc) {
          setBondPayment(proofDoc);
        }
        setIsPaymentLoading(false);
      });

      setIsContractStepComplete(
        bondableRequest.statusCode.code === BondStatusEnum.I352_SIGNED_BY_OBLIGOR,
      );
    }
  }, []);

  const handleSubmit = () => {
    if (isContractStepComplete && bondPayment) {
      // both contract and payment steps are complete so allow the submit to EARM
      setIsSubmitting(true);
      SubjectSearchApi.SubmitContractAndPayment(bondableRequest.bondRequestId)
        .then((cbr) => {
          // request now submitted for ICE review 2.
          navigate('/bond-request', { state: cbr.bondRequestId });
        })
        .catch((reason) => {
          setIsSubmitting(false);
          console.log('submit bond contract/payment error ', reason);
          //todo: display error message
        });
    }
  };

  if (!bondableRequest || isPaymentLoading) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container>
      <h3 className='mt-3'>Submit Bond Contract and Payment</h3>
      <div className='mb-3'>
        When your bond contract (I-352) and your bond payment are both complete, then you can submit
        your contract and payment for review by ICE.
      </div>
      <SubjectDisplay ceBondsSubject={ceBondsSubject} />
      {!isContractStepComplete && (
        <div className='mt-3'>
          <div>
            <FontAwesomeIcon className='me-1' icon={faExclamationCircle} color={'orange'} />
            <span className='fw-bold'>Bond Contract (I-352) - SIGNATURE NEEDED</span>
          </div>
          <div>Step 1 of 2 - You must complete and sign your I-352 contract.</div>
        </div>
      )}
      {isContractStepComplete && (
        <div className='mt-3'>
          <div>
            <FontAwesomeIcon icon={faCheckCircle} color={'green'} />
            <span className='fw-bold'> Bond Contract (I-352) - CONTRACT SIGNED</span>
          </div>
          <div>Step 1 of 2 - No further action is needed for the I-352 contract.</div>
        </div>
      )}
      <div>
        <Link className='fw-bold' to='/i352' state={bondableRequest}>
          I-352 Contract Information and Signature <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
      {isContractStepComplete && (
        <div className='mt-3'>
          {!bondPayment && (
            <div>
              <div>
                <FontAwesomeIcon icon={faExclamationCircle} color={'orange'} />
                <span className='fw-bold'> Bond Payment - RECEIPT NEEDED</span>
              </div>
              <div>
                Step 2 of 2 - You must complete your payment and upload your payment receipt.
              </div>
            </div>
          )}
          {bondPayment && (
            <div>
              <div>
                <FontAwesomeIcon icon={faCheckCircle} color={'green'} />
                <span className='fw-bold'> Bond Payment - RECEIPT UPLOADED</span>
              </div>
              <div>
                Step 2 of 2 - Your payment receipt is uploaded. No further action is needed.
              </div>
            </div>
          )}
          <div className='mb-3'>
            <Link className='fw-bold' to='/bond-payment' state={bondableRequest}>
              Bond Payment and Receipt <FontAwesomeIcon icon={faChevronRight} />
            </Link>
          </div>
        </div>
      )}
      <div className='pt-3 mb-3'>
        <Link to='/bond-request' state={bondableRequest.bondRequestId}>
          <Button className='me-3' variant='secondary'>
            <FontAwesomeIcon icon={faChevronLeft} className='me-1' /> Back
          </Button>
        </Link>
        {isContractStepComplete && bondPayment && (
          <span>
            <Button variant='primary' onClick={handleSubmit} disabled={isSubmitting}>
              Submit Contract and Payment
            </Button>
            {isSubmitting && (
              <span>
                <Spinner className='ms-1' animation='border' role='status' size='sm' />
                Please Wait...
              </span>
            )}
            <div className='mt-2 fw-bold'>
              <FontAwesomeIcon className='me-1' icon={faInfoCircle} />
              You have not yet submitted this bond payment request. You must click the "Submit
              Contract and Payment" button for this request to be processed.
            </div>
          </span>
        )}
      </div>
    </Container>
  );
};
