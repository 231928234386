import Api from './Api';
import { ICeBondsUser, IUserAlert } from './types';

class UserApi {
  static clearSecurityContext() {
    Api.GET('/user/clearSecurityContext');
  }

  static getCurrentUserInfo(): Promise<ICeBondsUser> {
    return Api.GET_raw('/user/getCurrent').then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    });
  }

  static saveEmail(email: string): Promise<ICeBondsUser> {
    return Api.POST('/user/saveEmail', email).then((response) => response as ICeBondsUser);
  }

  static getAllAlerts(): Promise<IUserAlert[]> {
    return Api.GET('/user/getAllUserAlerts').then((value) => value as IUserAlert[]);
  }

  static getVisibleAlerts(): Promise<IUserAlert[]> {
    //    return Api.GET_unauth('/user/getVisibleUserAlerts').then((value) => value as IUserAlert[]);

    return Api.GET_unauth('/user/getVisibleUserAlerts').then((response) => {
      if (response.status === 200) {
        return response.json();
      } else {
        return undefined;
      }
    });
  }
}

export default UserApi;
