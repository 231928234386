import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { IUserAlert } from '../api/types';
import UserApi from '../api/UserApi';

export const UserAlerts = () => {
  const [userAlertList, setUserAlertList] = useState<IUserAlert[]>();

  useEffect(() => {
    if (!userAlertList) {
      UserApi.getVisibleAlerts().then((alerts) => {
        setUserAlertList(alerts);
      });
    }
  }, [userAlertList]);

  if (!userAlertList) {
    return <></>;
  }

  return (
    <div>
      {userAlertList?.map((userAlert) => (
        <Alert key={userAlert.id} variant={userAlert.variant}>
          {userAlert.alertContent}
        </Alert>
      ))}
    </div>
  );
};
