import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ObligorDocumentApi from '../api/ObligorDocumentApi';
import { IBondRequestDocument, IObligorDocumentType, IObligorDocument } from '../api/types';
import { useErrorContext } from '../contexts/ErrorContext';
import ObligorDocumentTypeFilter from '../helper/ObligorDocumentTypeFilter';

export interface IUploadObligorDocModal {
  onObligorDocUploaded?: (uploadedDocument: IObligorDocument) => void;
  onBondDocUploaded?: (uploadedDocument: IBondRequestDocument) => void;
  obligorTypeCode?: string;
  bondRequestId?: number;
  buttonText: string;
}

const getButtonVariant = (buttonText: string | undefined) => {
  if (buttonText && buttonText === 'Add Document') {
    return 'primary';
  }

  return 'secondary';
};

const UploadObligorDocModal = ({
  onObligorDocUploaded,
  onBondDocUploaded,
  obligorTypeCode,
  bondRequestId,
  buttonText,
}: IUploadObligorDocModal) => {
  const [isObligorDocumentSaving, setIsObligorDocumentSaving] = useState<boolean>(false);
  const [fileControlFeedback, setFileControlFeedback] = useState<string>();
  const error = useErrorContext();
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [obligorDocumentTypeList, setObligorDocumentTypeList] = useState<IObligorDocumentType[]>();
  const [obligorDocumentTypeListFiltered, setObligorDocumentTypeListFiltered] =
    useState<IObligorDocumentType[]>();

  useEffect(() => {
    if (obligorTypeCode && obligorDocumentTypeList) {
      setObligorDocumentTypeListFiltered(
        ObligorDocumentTypeFilter.filterDocumentTypesByObligorType(
          obligorTypeCode,
          obligorDocumentTypeList,
        ),
      );
    }
  }, [obligorDocumentTypeList]);

  const handleHideDocumentModal = () => {
    setIsObligorDocumentSaving(false);
    setShowDocumentModal(false);
  };

  const handleShowDocumentModal = () => {
    if (!obligorDocumentTypeList) {
      ObligorDocumentApi.getObligorDocumentTypes().then((obligorDocumentTypes) =>
        setObligorDocumentTypeList(obligorDocumentTypes),
      );
    }

    setIsObligorDocumentSaving(false);
    setUploadDocumentValidated(false);
    setShowDocumentModal(true);
  };

  const [uploadDocumentValidated, setUploadDocumentValidated] = useState(false);

  const handleDocumentUpload = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const form = event.currentTarget.form;
    const fileControl = form?.fileSelectControl;
    const file = fileControl.files[0];

    if (fileControl.validity.valueMissing) {
      setFileControlFeedback('Please select a file to upload.');
      fileControl.setCustomValidity('ValueMissing');
    } else if (file.size > 20000000) {
      // file size limit is 20 MB
      setFileControlFeedback('The select file is too large. The file size limit is 20 MB.');
      fileControl.setCustomValidity('TooLarge');
    } else if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg'
    ) {
      setFileControlFeedback(
        'Type of selected file is not accepted. Accepted types: .pdf or .png or .jpg',
      );
      fileControl.setCustomValidity('TypeNotAccepted');
    } else {
      form?.fileSelectControl.setCustomValidity('');
    }
    fileControl.reportValidity();

    if (form?.checkValidity()) {
      const docTypeCode = form?.docTypeControl.value;
      const formData = new FormData();
      formData.append('file', file);

      setIsObligorDocumentSaving(true);

      if (onBondDocUploaded && bondRequestId) {
        // saving document as bond payment request attachment
        ObligorDocumentApi.addBondRequestObligorDoc(bondRequestId, docTypeCode, formData)
          .then((obligorDoc) => {
            // upload succeeded
            onBondDocUploaded(obligorDoc);
            handleHideDocumentModal();
          })
          .catch((reason) => {
            handleHideDocumentModal();
            error.setError('save bond document error ', reason);
          });
      }
      if (onObligorDocUploaded) {
        // saving document as obligor doc
        ObligorDocumentApi.saveObligorDocument(docTypeCode, formData)
          .then((obligorDoc) => {
            // upload succeeded
            onObligorDocUploaded(obligorDoc);
            handleHideDocumentModal();
          })
          .catch((reason) => {
            handleHideDocumentModal();
            error.setError('save obligor document error ', reason);
          });
      }
    }

    setUploadDocumentValidated(true);
  };

  return (
    <>
      <Button
        className='ms-1'
        variant={getButtonVariant(buttonText)}
        size='sm'
        onClick={handleShowDocumentModal}
      >
        {buttonText}
      </Button>
      <Modal show={showDocumentModal} onHide={handleHideDocumentModal}>
        <Form name='documentUploadForm' noValidate validated={uploadDocumentValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Identity Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!obligorDocumentTypeList && (
              <div>
                <Spinner animation='border' role='status' size='sm' />
                Loading...
              </div>
            )}
            {obligorDocumentTypeList && (
              <div>
                <div className='mb-3'>
                  Please upload a picture or scan for your proof of identity. The file you upload
                  must correspond to the selected document type.
                </div>
                {obligorTypeCode === 'NonProfit' && (
                  <div className='mb-3'>
                    Please combine the following items into a single file. IRS Letter 947 (Letter of
                    Determination) and SS-4 IRS Notification Letter (Employer Identification Number
                    (EIN) approval letter)
                  </div>
                )}
                {obligorTypeCode === 'LawFirm' && (
                  <div className='mb-3'>
                    Provide your SS-4 IRS Notification Letter (Employer Identification Number (EIN)
                    approval letter)
                  </div>
                )}
                <Form.Group controlId='formDocTypeSelect' className='mb-2'>
                  <Form.Select
                    name='docTypeControl'
                    className='form-control'
                    required
                    aria-label='Select Document Type'
                  >
                    <option value=''>Select Document Type</option>
                    {obligorDocumentTypeListFiltered?.map((obligorDocTypeItem) => (
                      <option value={obligorDocTypeItem.code}>
                        {obligorDocTypeItem.description}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    Please select a document type.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='formFile' className='mb-2'>
                  <Form.Control
                    type='file'
                    name='fileSelectControl'
                    accept='application/pdf, image/png, image/jpg, image/jpeg'
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    {fileControlFeedback}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className='small mt-3'>
                  Note: this document will be saved for future bond payment requests.
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isObligorDocumentSaving && (
              <span className='mr-3'>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </span>
            )}
            <Button
              variant='primary'
              onClick={handleDocumentUpload}
              disabled={isObligorDocumentSaving}
            >
              Upload File
            </Button>
            <Button variant='secondary' onClick={handleHideDocumentModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UploadObligorDocModal;
