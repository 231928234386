import { Link } from 'react-router-dom';
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Stack } from 'react-bootstrap';
import { BondableRequest } from '../../models/bond-data';
import { BondStatusEnum } from '../../models/status';
import { BondStatusContent } from '../BondStatusContent';
import { IBondRequestDocument } from '../../api/types';

export const SignContractAction = ({
  bondRequest,
  bondI220B,
}: {
  bondRequest: BondableRequest;
  bondI220B: IBondRequestDocument | undefined;
}) => {
  if (bondI220B && !bondI220B.viewedDate) {
    // There is a I220B for this BPR but it has not been viewed yet, so don't show the sign contract action until the I220B is viewed.
    return <></>;
  }

  if (
    bondRequest.statusCode.code === BondStatusEnum.APPROVED_BONDABLE_REQUEST ||
    (bondRequest.statusCode.code === BondStatusEnum.DENIED_I352_REQUEST &&
      bondRequest.denialReason === 'Other')
  ) {
    return (
      <ListGroup.Item variant='warning'>
        <Stack direction='horizontal'>
          <div>
            <FontAwesomeIcon className='pe-3 fs-2' icon={faExclamationCircle} color={'orange'} />
          </div>
          <div>
            <div>
              <div className='fw-bold'>Action Required: </div>
              <div>
                <BondStatusContent bondStatusCode={bondRequest.statusCode.code} />
                {bondRequest.statusCode.code === BondStatusEnum.APPROVED_BONDABLE_REQUEST && (
                  <span>
                    You may now proceed with completing the bond contract and payment as provided
                    for on ICE Form I-352, <em>Immigration Bond</em>.
                    <br />
                    <b>Note:</b> The completion of the I-352 contract and bond payment should be
                    done immediately to help ensure the timely processing of the bond and release of
                    the noncitizen.
                  </span>
                )}
                {bondRequest?.denialReason && (
                  <div className='mt-3'>
                    Denial Reason: Other
                    <div className='mt-1'>See comments below from ICE for more detail.</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mt-2 mb-2'>
              <Link to='/i352' state={bondRequest}>
                <Button>
                  Sign Contract
                  <FontAwesomeIcon className='ps-2' icon={faChevronRight} />
                </Button>
              </Link>
            </div>
          </div>
        </Stack>
      </ListGroup.Item>
    );
  }

  return <></>;
};
