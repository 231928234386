export const NAMESPACE = '/api';

/**
 * Handles the error with a Response object. Logs the error and throws an IError object.
 *
 * @param endpoint The path of the URL requested
 * @param response The response from the fetch invocation
 * @throws IError An object error
 */
const handleResponseError = async (endpoint: string, response: Response) => {
  if (response.status !== 200) {
    console.error(`API Failed: (${endpoint})`, response);

    return {
      message: `API Error: ${response.status} ${response.statusText}`,
      error: true,
      statusCode: response.status,
    };
  }
};

/**
 * Handles an unknown error, so we log the message and throw a generic message
 *
 * @param endpoint The path of the URL requested
 * @param error The error object/string/whatever is passed in
 * @throws IError
 */
const handleUnknownError = (endpoint: string, error: any) => {
  console.error(`Unknown API Error: (${endpoint})`, error);
  //todo: I don't like this, so look for a better way
  //TODO: I dont' think this function is needed.
  if ('message' in error) return error;
  else return { message: 'An unknown error has occurred' };
};

class Api {
  static _getAuthHeader = () => {
    const oktaTokenStorageRaw: any = localStorage.getItem('okta-token-storage');

    if (oktaTokenStorageRaw) {
      const oktaTokenStorage = JSON.parse(oktaTokenStorageRaw);
      return {
        Authorization: `Bearer ${oktaTokenStorage.accessToken.accessToken}`,
      };
    }
  };

  static GET(endpoint: string): Promise<any> {
    return fetch(NAMESPACE + endpoint, {
      headers: this._getAuthHeader(),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return handleResponseError(endpoint, response);
        }
      })
      .catch((reason) => {
        return handleUnknownError(endpoint, reason);
      });
  }

  static GET_raw(endpoint: string): Promise<Response> {
    return fetch(NAMESPACE + endpoint, { headers: this._getAuthHeader() }).then((response) => {
      return response;
    });
  }

  static GET_unauth(endpoint: string): Promise<Response> {
    // for calls where user is not authed
    return fetch(NAMESPACE + endpoint).then((response) => {
      return response;
    });
  }

  static POST(endpoint: string, body: string): Promise<any> {
    return this.POST_DIRECT(NAMESPACE, endpoint, body);
  }

  static POST_DIRECT(namespace: string, endpoint: string, body: string): Promise<any> {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...this._getAuthHeader() },
      body: body,
    };
    return fetch(namespace + endpoint, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return handleResponseError(endpoint, response);
        }
      })
      .catch((reason) => {
        return handleUnknownError(endpoint, reason);
      });
  }

  static POST_file(endpoint: string, file: FormData): Promise<any> {
    const requestOptions = {
      method: 'POST',
      body: file,
      headers: {
        ...Api._getAuthHeader(),
      },
    };
    return fetch(NAMESPACE + endpoint, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else return handleResponseError(endpoint, response);
      })
      .catch((reason) => {
        return handleUnknownError(endpoint, reason);
      });
  }

  static GET_file(endpoint: string): Promise<any> {
    return fetch(NAMESPACE + endpoint, {
      headers: this._getAuthHeader(),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
      })
      .catch((reason) => {
        return handleUnknownError(endpoint, reason);
      });
  }
}

export default Api;
