class FormattingUtil {
  static formatZip(zip: string): string {
    if (zip.length === 9) {
      return zip.substring(0, 5) + '-' + zip.substring(5);
    }

    if (zip.length === 5) {
      return zip;
    }

    return '';
  }

  static formatPhone(phone: string): string {
    if (phone.length === 10) {
      return '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6);
    }

    return '';
  }
}

export default FormattingUtil;
