import { Link } from 'react-router-dom';
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Stack } from 'react-bootstrap';
import { BondableRequest } from '../../models/bond-data';
import { BondStatusEnum } from '../../models/status';
import { BondStatusContent } from '../BondStatusContent';

export const AddressAction = ({ bondRequest }: { bondRequest: BondableRequest | undefined }) => {
  if (
    bondRequest &&
    (bondRequest.statusCode.code === BondStatusEnum.I391_VIEWED ||
      bondRequest.statusCode.code === BondStatusEnum.I323_VIEWED)
  ) {
    return (
      <>
        <ListGroup.Item variant='warning'>
          <Stack direction='horizontal'>
            <div>
              <FontAwesomeIcon className='pe-3 fs-2' icon={faExclamationCircle} color={'orange'} />
            </div>
            <div>
              <div>
                <span className='fw-bold'>Action Required: </span>
                <span>
                  <BondStatusContent bondStatusCode={bondRequest.statusCode.code} />
                </span>
              </div>
              <div className='mt-2 mb-2'>
                <Link to='/reviewaddress' state={bondRequest.bondRequestId}>
                  <Button>
                    Review Address
                    <FontAwesomeIcon className='ps-2' icon={faChevronRight} />
                  </Button>
                </Link>
              </div>
            </div>
          </Stack>
        </ListGroup.Item>
      </>
    );
  }
  if (bondRequest && bondRequest.statusCode.code === BondStatusEnum.REFUND_ADDR_CORRECT) {
    return (
      <ListGroup.Item>
        <Stack direction='horizontal'>
          <div>
            <div>
              <small>
                <div>
                  <span className='fw-bold'>Optional: </span>
                  <span>You may review your refund mailing address again if needed.</span>
                </div>
                <div className='mt-2 mb-2'>
                  <Link to='/reviewaddress' state={bondRequest.bondRequestId}>
                    <Button variant='secondary' size='sm'>
                      Review Address
                      <FontAwesomeIcon className='ps-2' icon={faChevronRight} />
                    </Button>
                  </Link>
                </div>
              </small>
            </div>
          </div>
        </Stack>
      </ListGroup.Item>
    );
  }

  return <></>;
};
