import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Container, Alert, Table } from 'react-bootstrap';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { BondableRequest } from '../models/bond-data';
import { useCeBondsUserContext } from '../contexts/UserContext';
import LoadingPage from './util/LoadingPage';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import Utils from '../api/Utils';
import BondStatusUtil from '../helper/BondStatusUtil';
import { UserAlerts } from '../components/UserAlerts';
import { useOktaAuth } from '@okta/okta-react';

export const LandingPage = () => {
  const navigate = useNavigate();
  const [userBondRequests, setUserBondRequests] = useState<BondableRequest[]>();
  const ceBondsUser = useCeBondsUserContext();
  const { oktaAuth, authState } = useOktaAuth();
  const [isHeadDone, setIsHeadDone] = useState(false);

  const error = useErrorContext();

  useEffect(() => {
    document.title = 'Home - ICE CeBONDS';

    console.info('process.env: ', process.env);
    console.info('REACT_APP_SHOW_DAP_SCRIPT: ', process.env.REACT_APP_SHOW_DAP_SCRIPT);

    if (!isHeadDone && process.env.REACT_APP_SHOW_DAP_SCRIPT === 'yes') {
      var dapScript = document.createElement('script');
      dapScript.setAttribute('async', '');
      dapScript.setAttribute('id', '_fed_an_ua_tag');
      dapScript.setAttribute(
        'src',
        'https://dap.digitalgov.gov/Universal-Federated-Analytics-Min.js?agency=DHS',
      );
      dapScript.setAttribute('type', 'text/javascript');
      document.head.appendChild(dapScript);
      setIsHeadDone(true);

      console.info(
        "We participate in the US government's analytics program. See the data at analytics.usa.gov.",
      );
    }

    error.clearError(); // clears error banner on page load
  }, []);

  useEffect(() => {
    if (ceBondsUser?.userId) {
      SubjectSearchApi.getBondsForUser()
        .then((responseData: BondableRequest[] | IApiError) => {
          if (Utils.isError(responseData)) {
            const errorData = responseData as IApiError;
            error.setError(errorData.message);
            setUserBondRequests([]);
          } else if ('length' in responseData) {
            if (responseData.length === 0) {
              setUserBondRequests([]);
            } else {
              setUserBondRequests(responseData);
            }
          }
        })
        .catch((err) => {
          error.setError(`Failed to search: ${err}`);
          setUserBondRequests([]);
          console.error('SubjectSearchApi.getBondsByUserIdApi failed', err);
        });
    }
  }, [ceBondsUser]);

  // const [filteraNumber, setFilteraNumber] = useState<string>('');
  // const onChangeFilteraNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilteraNumber(event.target.value);
  // };

  // const handleFilter = () => {
  //   if (ceBondsUser) {
  //     SubjectSearchApi.getBondsByANumber(filteraNumber).then(
  //       (responseData: BondableRequest[] | IApiError) => {
  //         if (Utils.isError(responseData)) {
  //           const errorData = responseData as IApiError;
  //           error.setError(errorData.message);
  //           setUserBondRequests([]);
  //         } else if ('length' in responseData) {
  //           if (responseData.length === 0) {
  //             setUserBondRequests([]);
  //           } else {
  //             setUserBondRequests(responseData);
  //           }
  //         }
  //       },
  //     );
  //   }
  // };

  const handleLogin = () => {
    oktaAuth.signInWithRedirect();
  };

  const handleRowClick = (bondRequestId: number) => {
    navigate('/bond-request', { state: bondRequestId });
  };

  const unauthenticatedView = () => {
    if (!authState?.isAuthenticated) {
      return (
        <div>
          <p className='fw-bold'>
            Please read these terms. Scroll to the bottom of this page to Sign In.
          </p>
          <h4>Legal Notice:</h4>
          <p>
            By signing in, you are acknowledging that you have read and agree to the Warning,
            Privacy Act Notice, and any Rules of Behavior displayed on this page.
          </p>
          <h5>WARNING</h5>
          <p>
            You are accessing a U.S. Government information system. This information system is
            provided for U.S. Government-authorized use only. Unauthorized or improper use or access
            of this system may result in civil or criminal penalties. Absence of access controls IS
            NOT authorization for access! By using this information system, you understand and
            consent to the following: You have no reasonable expectation of privacy when you use
            this information system, including any communications or data transiting, stored on,
            originated from, or directed to this information system; at any time, and for any lawful
            government purpose, the government may monitor, intercept, search and seize any
            communication or data transiting, stored on, originated from, or directed to or from
            this information system; the government may disclose or use any communications or data
            transiting, stored on, originated from, or directed to or from this information system
            for any lawful government purpose. Use of this system constitutes consent to such
            monitoring.
          </p>
          <p>
            You are NOT authorized to process classified information on this information system.
          </p>
          <h5>PRIVACY STATEMENT</h5>
          <p>
            <span className='fw-bold'>Authority and Purpose: </span>The Immigration and Nationality
            Act, as amended, (8 U.S.C. 1103, 1183, 1226, 1229c, and 1363) authorizes the collection
            of this information to provide for the posting, maintenance, cancellation, and breach of
            an immigration surety bond, and for associated financial management activities,
            including collection of unpaid monies, reimbursement of the bond principal, and the
            calculation, payment, and reporting of interest. The Internal Revenue Code (26 U.S.C.
            6109) and Executive Order 9397 authorize the collection of the Social Security number
            (SSN).
          </p>
          <p>
            <span className='fw-bold'>Disclosure: </span>Furnishing this information is voluntary;
            however, failure to provide it will result in the non-issuance of the immigration bond.
            For cash bonds, your SSN is necessary to pay interest through the U.S. Treasury
            Department and to comply with Internal Revenue Service requirements to report interest
            payments.
          </p>
          <p>
            <span className='fw-bold'>Routine Uses: </span>This information will be used by and
            disclosed to DHS personnel and contractors or other agents who need the information to
            support the enforcement of immigration laws and the provision of immigration benefits.
            DHS may share this information with the U.S. Treasury Department to report interest paid
            to an obligor, and to facilitate payments to or collection of monies owed by an obligor.
            DHS may also share this information with the U.S. Justice Department and other Federal
            and State agencies for collection, enforcement, investigatory, or litigation purposes,
            or as otherwise authorized pursuant to its published Privacy Act system of records
            notice.
          </p>
          <div className='fw-bold'>Public Reporting Burden.</div>
          <p>
            Under the Paperwork Reduction Act, an agency may not sponsor an information collection
            and a person is not required to respond to a collection of information unless it
            displays a currently valid OMB control number. We try to create forms and instructions
            that are accurate, can be easily understood, and which impose the least possible burden
            on you to provide us with information. Often this is difficult because some immigration
            laws are very complex. The estimated average time to complete and file this application
            is 30 minutes per application. If you have comments regarding the accuracy of this
            estimate, or suggestions for making this form simpler, you can write to the Department
            of Homeland Security, U.S. Immigration and Customs Enforcement, Records Management
            Branch PRA PMO, 500 12th Street S.W., Room 3117, Washington, D.C. 20024 Consent to
            Receive Electronic Notices. By utilizing the CeBONDS system you agree to allow
            Immigration and Customs Enforcement (ICE) to communicate with you electronically and you
            consent to electronic delivery of bond notices and documents from ICE via the CeBONDS
            system and e-mail. You also agree to check your CeBONDS account, alerts, and messages,
            and the e-mail account associated with your CeBONDS account (including spam and bulk
            folders) on a reasonably regular basis to stay apprised of important notices and
            information about your account and the bond you posted. You undertake to update your
            email in the event you change your email address.
          </p>
          <h4>Consent to Receive Electronic Notices.</h4>
          <p>
            By utilizing the CeBONDS system you agree to allow Immigration and Customs Enforcement
            (ICE) to communicate with you electronically and you consent to electronic delivery of
            bond notices and documents from ICE via the CeBONDS system and e-mail. You also agree to
            check your CeBONDS account, alerts, and messages, and the e-mail account associated with
            your CeBONDS account (including spam and bulk folders) on a reasonably regular basis to
            stay apprised of important notices and information about your account and the bond you
            posted. You undertake to update your email in the event you change your email address.
          </p>
          <div>
            <Button className='mt-3' onClick={() => handleLogin()} variant='primary'>
              Sign In or Sign Up
            </Button>
          </div>
        </div>
      );
    }
  };

  const authenticatedView = () => {
    if (authState?.isAuthenticated) {
      if (!userBondRequests) return <LoadingPage message={'Loading bond payment requests...'} />;

      return (
        <div>
          {BondStatusUtil.isListActionNeeded(userBondRequests) && (
            <Alert variant='warning'>
              <FontAwesomeIcon className='me-2' icon={faExclamationCircle} color={'orange'} />
              Action is required on one or more of your bond payment requests below.
            </Alert>
          )}
          {userBondRequests?.length === 0 && (
            <p>
              No recent bond payment requests were found for you. Start a new bond payment request.
            </p>
          )}
          {userBondRequests?.length > 0 && (
            <p>
              Click below on your recent bond payment request(s) or start a new bond payment
              request.
            </p>
          )}
          {userBondRequests && userBondRequests.length > 0 && (
            <Table size='sm' responsive hover>
              <thead>
                <tr>
                  <th>Date Created</th>
                  <th>A-Number</th>
                  <th>Last, First Name</th>
                  <th>Bond Status</th>
                </tr>
              </thead>
              <tbody>
                {userBondRequests?.map((bondRequestItem) => (
                  <tr
                    className={BondStatusUtil.isActionNeeded(bondRequestItem) ? 'action-row' : ''}
                    key={bondRequestItem.bondRequestId}
                    data-testid='result-row'
                    onClick={() => handleRowClick(bondRequestItem.bondRequestId)}
                    role='button'
                  >
                    <td>{Utils.formatDisplayDate(bondRequestItem.createDate)}</td>
                    <td>{bondRequestItem.anumber}</td>
                    <td>
                      {bondRequestItem.lastName}, {bondRequestItem.firstName}
                    </td>
                    <td>
                      {BondStatusUtil.isActionNeeded(bondRequestItem) && (
                        <FontAwesomeIcon
                          className='me-1'
                          icon={faExclamationCircle}
                          color={'orange'}
                        />
                      )}
                      {BondStatusUtil.getBondStatusDescription(bondRequestItem)}{' '}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <div className='pt-3'>
            <Link to='/subject'>
              <Button variant='primary'>
                <FontAwesomeIcon icon={faPlus} className='me-1' /> Bond Payment Request
              </Button>
            </Link>
          </div>
        </div>
      );
    }
  };

  return (
    <Container>
      <h3 className='pt-3 pb-3' text-align='center'>
        Welcome to ICE CeBONDS
      </h3>
      <UserAlerts />
      {unauthenticatedView()}
      {authenticatedView()}
    </Container>
  );
};
