import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import FormModelSheetSignatureInput from '../../components/FormModelSheetSignatureInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from '../util/LoadingPage';
import ObligorDocumentApi from '../../api/ObligorDocumentApi';

const SignI352 = () => {
  const location = useLocation();
  const bondRequestId = location.state as number;
  const navigate = useNavigate();

  const [signatureImage, setSignatureImage] = useState('');
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [objectURL, setObjectURL] = useState<string>();

  useEffect(() => {
    document.title = 'I-352 Preview - ICE CeBONDS';

    if (!objectURL) {
      setIsLoadingPreview(true);
      ObligorDocumentApi.previewI352Doc(bondRequestId).then((blob) => {
        setObjectURL(URL.createObjectURL(blob));
        setIsLoadingPreview(false);
      });
    }
  }, [objectURL, bondRequestId]);

  const doSignForm = () => {
    setIsSaving(true);
    ObligorDocumentApi.signI352Doc(bondRequestId, signatureImage).then((signedI352) => {
      setIsSaving(false);
      navigate('/bond-request', { state: signedI352.bondRequestId });
    });
  };

  if (isLoadingPreview || isSaving) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <div>
      <div className='m-3'>
        <h3>FORM I-352 CONTRACT</h3>
        <div>
          Please review and sign your I-352 agreement.{' '}
          <span className='fw-bold'>Scroll to the bottom of this page for signature options.</span>
        </div>
      </div>
      <div>
        <iframe className='w-100 min-vh-100' src={objectURL} title='I-352 PDF Preview'></iframe>
      </div>
      <div className='m-3'>
        <div className='fw-bold'>Sign Here:</div>
        <FormModelSheetSignatureInput
          onChange={(signatureImage) => setSignatureImage(signatureImage)}
          onSign={doSignForm}
          scaleHeight={20}
        />
        <div className={'mt-3'}>
          <hr />
          <Link to='/bond-request' state={bondRequestId}>
            <Button variant='secondary' className='me-3'>
              Cancel
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignI352;
