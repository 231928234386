import { BondableRequest } from '../models/bond-data';
import { BondPayment } from '../models/bond-data';
import React, { useEffect, useState } from 'react';
import { Container, Form, Button, Spinner, Modal, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PaymentApi from '../api/PaymentApi';
import LoadingPage from './util/LoadingPage';
import ObligorApi from '../api/ObligorApi';
import { IObligor } from '../api/types';
import { useErrorContext } from '../contexts/ErrorContext';
import ObligorDocumentApi from '../api/ObligorDocumentApi';
import Utils from '../api/Utils';
import { BondStatusEnum } from '../models/status';

export const BondPaymentUpload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const error = useErrorContext();

  const bondableRequest = location.state as BondableRequest;
  const [isInstructionsDownloading, setIsInstructionsDownloading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = React.useState(true);
  const [isObligorLoading, setIsObligorLoading] = React.useState(true);
  const [bondPayment, setBondPayment] = useState<BondPayment>();
  const [obligor, setObligor] = useState<IObligor>();
  const [isBondPaymentSaving, setIsBondPaymentSaving] = useState<boolean>();
  const [isBondPaymentDownloading, setIsBondPaymentDownloading] = useState<boolean>();
  const [bondPaymentFeedback, setBondPaymentFeedback] = useState<string>();
  const [bondPaymentValidated, setBondPaymentValidated] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);
  const handleDocumentModalClose = () => setShowDocumentModal(false);
  const handleShowInstructions = () => setShowInstructions(true);
  const handleHideInstructions = () => setShowInstructions(false);

  useEffect(() => {
    document.title = 'Bond Payment - ICE CeBONDS';

    //load bond payment proof document if exists
    if (bondableRequest && !bondPayment) {
      PaymentApi.getBondPayment(bondableRequest.bondRequestId).then((proofDoc) => {
        setBondPayment(proofDoc);
        if (proofDoc) {
          setShowInstructions(false);
        }

        setIsPaymentLoading(false);
      });
    }

    if (!obligor) {
      ObligorApi.getObligor().then((cbo) => {
        if (cbo && cbo.obligorId) {
          setObligor(cbo);
        }
        setIsObligorLoading(false);
      });
    }
  }, []);

  const handleShowDocumentModal = () => {
    setIsBondPaymentSaving(false);
    setBondPaymentValidated(false);
    setShowDocumentModal(true);
  };

  const handleBondPaymentUpload = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const form = event.currentTarget.form;
    const paymentFileControl = form?.paymentFileSelectControl;
    const paymentFile = paymentFileControl.files[0];

    if (paymentFileControl.validity.valueMissing) {
      setBondPaymentFeedback('Please select a file to upload.');
      paymentFileControl.setCustomValidity('ValueMissing');
    } else if (paymentFile.size > 20000000) {
      // file size limit is 20 MB
      setBondPaymentFeedback('The selected file is too large. The file size limit is 20 MB.');
      paymentFileControl.setCustomValidity('TooLarge');
    } else if (
      paymentFile.type !== 'application/pdf' &&
      paymentFile.type !== 'image/png' &&
      paymentFile.type !== 'image/jpg' &&
      paymentFile.type !== 'image/jpeg'
    ) {
      setBondPaymentFeedback(
        'Type of selected file is not accepted. Accepted types: .pdf or .png or .jpg',
      );
      paymentFileControl.setCustomValidity('TypeNotAccepted');
    } else {
      form?.paymentFileSelectControl.setCustomValidity('');
    }
    paymentFileControl.reportValidity();

    if (form?.checkValidity()) {
      const formData = new FormData();
      formData.append('file', paymentFile);

      setIsBondPaymentSaving(true);

      PaymentApi.saveBondPayment(bondableRequest.bondRequestId, formData)
        .then((bp) => {
          // bond payment proof save succeeded
          setBondPayment(bp);
          setIsBondPaymentSaving(false);
          setShowDocumentModal(false);
          navigate('/bond-request', { state: bondableRequest.bondRequestId });
        })
        .catch((reason) => {
          setShowDocumentModal(false);
          error.setError('save payment document error ', reason);
        });
    }

    setBondPaymentValidated(true);
  };

  const handleBondPaymentDownload = () => {
    console.log('downloading bond payment...');
    if (bondPayment) {
      setIsBondPaymentDownloading(true);
      PaymentApi.downloadBondPaymentFile(bondableRequest.bondRequestId).then((blob) => {
        Utils.downloadBlob(blob, bondPayment.name);
        setIsBondPaymentDownloading(false);
      });
    }
  };

  const getObligorAddress = () => {
    let obligorAddress = '';
    if (obligor) {
      obligorAddress += obligor.street1 + ', ';
      obligorAddress += obligor.street2 ? obligor.street2 + ', ' : '';
      obligorAddress += obligor.street3 ? obligor.street3 + ', ' : '';
      obligorAddress += obligor.city + ', ' + obligor.stateCd + ' ' + obligor.zip;
    }

    return obligorAddress;
  };

  const handleInstructionDownload = () => {
    //Get merged payment instructions PDF
    setIsInstructionsDownloading(true);
    ObligorDocumentApi.payInstructionsDoc(bondableRequest.bondRequestId).then((blob) => {
      var url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'CeBONDSPayInstructions');
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsInstructionsDownloading(false);
    });
  };

  if (isPaymentLoading || isObligorLoading) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  return (
    <Container className='mt-3'>
      <h3>Bond Payment</h3>
      <div className='mb-3'>Follow steps 1 and 2 below to complete your bond payment.</div>
      <div className='mb-3'>
        <div className='fw-bold'>Your payment information:</div>
        <div>Obligor Name: {obligor?.name || obligor?.lastName + ', ' + obligor?.firstName}</div>
        <div>Bond Number: {bondableRequest.earmBondNumber}</div>
        <div>
          Payment amount:&nbsp;
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            bondableRequest.bondAmount,
          )}
        </div>
      </div>
      {bondPayment && (
        <div className='mb-3'>
          <span className='fw-bold'>Step 1 - </span> It appears that you previously submitted a
          payment receipt. Proceed to Step 2 if the contract was denied for reasons that were
          unrelated to the payment. Click Show Instructions if you need to see the payment
          instructions.
          <br />
          {!showInstructions && (
            <Button variant='secondary' size='sm' onClick={handleShowInstructions}>
              Show Step 1 (Instructions)
            </Button>
          )}
          {showInstructions && (
            <Button variant='secondary' size='sm' onClick={handleHideInstructions}>
              Hide Step 1 (Instructions)
            </Button>
          )}
        </div>
      )}
      {showInstructions && (
        <Card className='mb-3'>
          <Card.Header>
            <Card.Title>Step 1 - Payment Instructions</Card.Title>
            <Card.Text>
              See your bond payment instructions below. Take these payment instructions to your bank
              to execute your bond payment transaction. We suggest you print this page or take your
              device with you to the bank. You may use either the Fedwire or ACH option. Ask your
              bank which option is best for you. After completing this step, please go to{' '}
              <span className='fw-bold'>Step 2</span> below. Be sure to get a transaction receipt
              from your bank to use in Step 2.
            </Card.Text>
          </Card.Header>
          <Card.Body>
            <div>
              <div>
                <h5 className='text-center'>WIRE Instructions for CeBONDS payment</h5>
                <div>
                  For the submission of wire payments to U.S. Immigration and Customs Enforcement
                  for the payment of Immigration Bond, please provide the following instructions to
                  your Financial Institution.
                </div>
                <Table size='sm' bordered responsive>
                  <thead>
                    <tr>
                      <td>Wire Field Tag</td>
                      <td>Wire Field Name</td>
                      <td>Information (required)</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&#123;1510&#125;</td>
                      <td>Type/Subtype</td>
                      <td>1000</td>
                    </tr>
                    <tr>
                      <td>&#123;2000&#125;</td>
                      <td>
                        Bond Amount <em>(enter payment amount)</em>
                      </td>
                      <td className='fw-bold'>${bondableRequest.bondAmount}</td>
                    </tr>
                    <tr>
                      <td>&#123;3400&#125;</td>
                      <td>Receiver ABA routing number *</td>
                      <td className='fw-bold'>021030004</td>
                    </tr>
                    <tr>
                      <td>&#123;3400&#125;</td>
                      <td>Receiver ABA short name</td>
                      <td>TREAS NYC</td>
                    </tr>
                    <tr>
                      <td>&#123;3600&#125;</td>
                      <td>Business Function Code</td>
                      <td>CTR</td>
                    </tr>
                    <tr>
                      <td>&#123;4200&#125;</td>
                      <td>Beneficiary Identifier Account Number</td>
                      <td className='fw-bold'>870191513001</td>
                    </tr>
                    <tr>
                      <td>&#123;4200&#125;</td>
                      <td>Beneficiary Name & Address</td>
                      <td>
                        ICECeBONDSPAYMENTS
                        <br />
                        Address: 500 12th Street SW
                        <br />
                        Washington DC 20536
                      </td>
                    </tr>
                    <tr>
                      <td>&#123;5000&#125;</td>
                      <td>
                        Originator <em>(enter the name of the originator of the payment)</em>
                      </td>
                      <td>{obligor?.name || obligor?.lastName + ', ' + obligor?.firstName}</td>
                    </tr>
                    <tr>
                      <td>&#123;6000&#125;</td>
                      <td>
                        Originator to Beneficiary Information - Line 1 Bond #. <br />
                        <span className='fw-bold'>
                          Please ensure the Bond # is entered in the remarks/comments/memo section
                          on the payment document and receipt.
                        </span>
                      </td>
                      <td className='fw-bold'>Bond # {bondableRequest.earmBondNumber}</td>
                    </tr>
                    <tr>
                      <td>&#123;6000&#125;</td>
                      <td>Originator to Beneficiary Information – Line 2 Originator's Address</td>
                      <td>{getObligorAddress()}</td>
                    </tr>
                  </tbody>
                </Table>
                <small>
                  Additional Instructions for Originator (remitter):
                  <ul>
                    <li>
                      The obligor for the bond and the originator (remitter) of the funds must be
                      the same person.
                    </li>
                  </ul>
                  Additional Instructions for Bank:
                  <ul>
                    <li className='fw-bold text-danger'>
                      Please ensure the Bond # is entered in the remarks/comments/memo section for
                      the payment.
                    </li>
                    <li>
                      The Beneficiary address and financial institution address for Treasury's
                      routing number is{' '}
                      <span className='fw-bold'>33 Liberty Street, New York, NY 10045</span>
                    </li>
                  </ul>
                  Questions regarding these instructions should be directed to Carl Albritton
                  202-497-6755 or Melinda Jones 202-271-9855.
                </small>
              </div>
              <div className='mt-1'>
                <hr></hr>
                <h5 className='text-center'>ACH CREDIT Instructions for CeBONDS payment</h5>
                <div>
                  For the remittance of Automated Clearing House (ACH) credits to the U.S.
                  Immigration and Customs Enforcement for the payment of immigration Bond, please
                  provide the following instructions to your Financial Institution.
                </div>
                <Table size='sm' bordered responsive>
                  <thead>
                    <tr>
                      <td>
                        NACHA
                        <br />
                        Record Type Code
                      </td>
                      <td>
                        NACHA
                        <br />
                        Field
                      </td>
                      <td>
                        NACHA
                        <br />
                        Data Element Name
                      </td>
                      <td>Required Information</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>5</td>
                      <td>3</td>
                      <td>Name of Payor/Remitter (Bond Obligor)</td>
                      <td>{obligor?.name || obligor?.lastName + ', ' + obligor?.firstName}</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>6</td>
                      <td>Standard Entry Class Code</td>
                      <td>CCD+</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>9</td>
                      <td>
                        Effective Entry Date{' '}
                        <em>(enter the date the bond is paid---intended settlement date)</em>
                      </td>
                      <td>{new Date().toDateString()}</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>2</td>
                      <td>Transaction Code*</td>
                      <td>22</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>3 & 4</td>
                      <td>Receiving DFI Identification (ABA routing #)</td>
                      <td>051036706</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>5</td>
                      <td>
                        DFI Account Number <em>(agency account number)</em>
                      </td>
                      <td>870191513001</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>6</td>
                      <td>
                        Bond Amount <em>(enter payment amount)</em>
                      </td>
                      <td className='fw-bold'>${bondableRequest.bondAmount}</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>8</td>
                      <td>
                        Receiving Company Name <em>(enter the identifying information)</em>
                      </td>
                      <td>ICECeBONDSPAYMENTS</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>3</td>
                      <td>Payment Related Information</td>
                      <td className='fw-bold'>
                        {'RMR*IV*' +
                          bondableRequest.earmBondNumber +
                          '**' +
                          bondableRequest.bondAmount +
                          '\\'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <small>
                  Additional Payment Related Information should be included in NACHA Record 7 Field
                  3 and must conform to the ANSI ASC X12 standards using the EDI 820 Transaction Set
                  RMR Data Segment. The format is as follows.
                  <Table size='sm' responsive bordered>
                    <thead>
                      <tr>
                        <td align='center'>Data Segment</td>
                        <td align='center'>Delimiter</td>
                        <td align='center'>Code Qualifier</td>
                        <td align='center'>Delimiter</td>
                        <td align='center'>Bond #</td>
                        <td align='center'>Delimiter</td>
                        <td align='center'>Delimiter</td>
                        <td align='center'>Bond Payment Amount</td>
                        <td align='center'>Terminator</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td align='center'>RMR</td>
                        <td align='center'>*</td>
                        <td align='center'>IV</td>
                        <td align='center'>*</td>
                        <td align='center'>{bondableRequest.earmBondNumber}</td>
                        <td align='center'>*</td>
                        <td align='center'>*</td>
                        <td align='center'>{bondableRequest.bondAmount}</td>
                        <td align='center'>\</td>
                      </tr>
                    </tbody>
                  </Table>
                </small>
                <small>
                  <div className='mb-1'>
                    *ACH debits are not permitted to this ABA routing number. All debits received
                    will be automatically rejected/returned.
                  </div>
                  Additional Instructions for Originator (remitter):
                  <ul>
                    <li>
                      The obligor for the bond and the originator (remitter) of the funds must be
                      the same person.
                    </li>
                  </ul>
                  Additional Instructions for Bank:
                  <ul>
                    <li>
                      Please ensure the Bond # is entered in the remarks/comments/memo section for
                      the payment.
                    </li>
                  </ul>
                  Questions regarding these instructions should be directed to Carl Albritton
                  202-497-6755 or Melinda Jones 202-271-9855.
                </small>
              </div>
            </div>
          </Card.Body>
          <Card.Footer>
            <div>
              <span className='fw-bold'>Note:</span> Both ACH and Fedwire instructions are provided.
              Ask your bank or financial institution which option is best for you.
            </div>
            <small>
              <div className='mt-2'>
                <Button
                  className='m-0 p-0'
                  size='sm'
                  variant='link'
                  onClick={handleInstructionDownload}
                >
                  Download Instructions (optional)
                </Button>
                {isInstructionsDownloading && (
                  <div>
                    <Spinner animation='border' role='status' size='sm' />
                    &nbsp; Downloading...
                  </div>
                )}
              </div>
            </small>
          </Card.Footer>
        </Card>
      )}
      <Card className='mb-3'>
        <Card.Header>
          <Card.Title>Step 2 - Upload Payment Receipt</Card.Title>
          <Card.Text>
            After completing your bond payment transaction (as instructed in Step 1), upload a copy
            of the payment receipt provided to you by your bank. This upload is needed to show proof
            of payment to ICE. Note: only pdf, png and jpg files are accepted.
          </Card.Text>
        </Card.Header>
        <Card.Body>
          <div></div>
          <div className='mt-1'>
            <div className='mb-1'>
              <div>
                {bondPayment && (
                  <div className='mb-3'>
                    {bondableRequest?.statusCode.code === BondStatusEnum.I352_SIGNED_BY_OBLIGOR && (
                      <div className='mt-3'>
                        <span className='fw-bold'>NOTE:</span>
                        You need to upload a bond payment receipt. This will replace the previously
                        uploaded receipt. If the contract was denied for reasons unrelated to the
                        payment, you do NOT need to make a payment again (only the receipt is
                        needed).
                      </div>
                    )}
                    {bondableRequest?.statusCode.code !== BondStatusEnum.I352_SIGNED_BY_OBLIGOR && (
                      <div className='mt-3 fw-bold'>
                        You have already uploaded the following payment receipt. You do not need to
                        upload another receipt unless instructed to do so.
                      </div>
                    )}
                    <div className='mt-2'>
                      {bondPayment.name}
                      <Button
                        className='ms-2'
                        variant='link'
                        title='Download'
                        onClick={handleBondPaymentDownload}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </Button>
                    </div>
                    {isBondPaymentDownloading && (
                      <div>
                        <Spinner animation='border' role='status' size='sm' />
                        &nbsp; Downloading...
                      </div>
                    )}
                  </div>
                )}
                <Button size='sm' onClick={handleShowDocumentModal}>
                  Upload Your Receipt
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className='mt-4'>
        <Link to='/bond-request' state={bondableRequest.bondRequestId}>
          <Button variant='secondary'>
            <FontAwesomeIcon className='pe-2' icon={faChevronLeft} /> Back
          </Button>
        </Link>
      </div>
      <Modal show={showDocumentModal} onHide={handleDocumentModalClose}>
        <Form name='bondPaymentUploadForm' noValidate validated={bondPaymentValidated}>
          <Modal.Header closeButton>
            <Modal.Title>Bond Payment Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              Upload a copy of the payment receipt provided to you by your bank. Note: only pdf, png
              and jpg files are accepted.
            </div>
            <div>
              <Form.Group controlId='formFile' className='mb-2'>
                <Form.Control
                  type='file'
                  name='paymentFileSelectControl'
                  accept='application/pdf, image/png, image/jpg, image/jpeg'
                  required
                />
                <Form.Control.Feedback type='invalid'>{bondPaymentFeedback}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isBondPaymentSaving && (
              <span className='mr-3'>
                <Spinner animation='border' role='status' size='sm' className='me-1' />
                Please Wait...
              </span>
            )}
            <Button
              variant='primary'
              onClick={handleBondPaymentUpload}
              disabled={isBondPaymentSaving}
            >
              Upload File
            </Button>
            <Button variant='secondary' onClick={handleDocumentModalClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};
