import { useState } from 'react';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Spinner, Stack } from 'react-bootstrap';
import Utils from '../../api/Utils';
import { BondPayment } from '../../models/bond-data';
import PaymentApi from '../../api/PaymentApi';

export const PaymentProgress = ({ bondPayment }: { bondPayment: BondPayment | undefined }) => {
  const [isDocDownloading, setIsDocDownloading] = useState<boolean>(false);

  const handleDocDownload = () => {
    if (bondPayment) {
      console.log('downloading bond payment doc...');
      setIsDocDownloading(true);
      PaymentApi.downloadBondPaymentFile(bondPayment.bondRequest.bondRequestId).then((blob) => {
        Utils.downloadBlob(blob, bondPayment.name);
        setIsDocDownloading(false);
      });
    }
  };

  if (bondPayment) {
    return (
      <ListGroup.Item className='align-items-center'>
        <Stack direction='horizontal'>
          <div>
            <FontAwesomeIcon className='pe-3 fs-3' icon={faCheckCircle} color={'green'} />
          </div>
          <div>
            <div>
              You uploaded your bond payment receipt.
              <span className='small ms-2'>
                ({Utils.formatDisplayDate(bondPayment.createDate)})
              </span>
            </div>
            <div className='small'>
              <Button className='ps-1' variant={'link'} onClick={() => handleDocDownload()}>
                <span className='small'>Download Payment</span>{' '}
                <FontAwesomeIcon className='ps-2' icon={faDownload} title='Download' />
              </Button>
              {isDocDownloading && (
                <span className='ps-3'>
                  <Spinner animation='border' role='status' size='sm' />
                  &nbsp; Please Wait...
                </span>
              )}
            </div>
          </div>
        </Stack>
      </ListGroup.Item>
    );
  }

  return <></>;
};
