import { useContext, useEffect } from 'react';
import { IObligor } from '../../api/types';
import { BondableRequest } from '../../models/bond-data';
import ErrorPage from '../util/ErrorPage';
import ObligorEditModal from '../../components/ObligorEditModal';
import { useErrorContext } from '../../contexts/ErrorContext';
import SubjectSearchApi from '../../api/SubjectSearchApi';
import { CeBondsUserContext } from '../../contexts/UserContext';
import FormattingUtil from '../../helper/FormattingUtil';

export interface IObligorI352SummaryProps {
  obligor: IObligor | undefined;
  bondRequest?: BondableRequest;
  setObligor: (val: IObligor | undefined) => void;
}

const ObligorI352Summary = ({ obligor, bondRequest, setObligor }: IObligorI352SummaryProps) => {
  const ceBondsUser = useContext(CeBondsUserContext);
  const error = useErrorContext();

  useEffect(() => {
    document.title = 'Bond Contract (I-352) - ICE CeBONDS';
  }, []);

  const handleSavedObligor = (savedObligor: IObligor | undefined) => {
    setObligor(savedObligor); // updates state var of the parent
    obligor = savedObligor;

    // Update BMIS obligor on the request with saved obligor.
    if (savedObligor && bondRequest) {
      SubjectSearchApi.saveObligorForRequest(savedObligor, bondRequest.bondRequestId)
        .then(() => {
          // save to bmis succeeded
        })
        .catch((reason) => {
          error.setError('saveObligorForRequest failed. reason: ', reason);
        });
    }
  };

  const renderObligor = () => {
    if (!obligor) return <ErrorPage message={'Missing obligor information'} />;
    else {
      return (
        <>
          <div>{obligor.name || obligor.lastName + ', ' + obligor.firstName}</div>
          <div>{obligor.street1}</div>
          <div>{obligor.street2}</div>
          <div>{obligor.street3}</div>
          <div>{`${obligor.city}, ${obligor.stateCd} ${FormattingUtil.formatZip(
            obligor.zip,
          )}`}</div>
          <div>Phone: {FormattingUtil.formatPhone(obligor.phoneNbr)}</div>
          <div>TIN: {obligor.einTinNbr}</div>
        </>
      );
    }
  };

  const renderNonCitizen = () => {
    if (!bondRequest) return <ErrorPage message={'Missing bond payment request'} />;
    else {
      const arrivalMeans = bondRequest.portOfEntry || bondRequest.arrivalState || '--';

      return (
        <>
          <div>
            {bondRequest.lastName}, {bondRequest.firstName}
          </div>
          <div>Date of Birth: {bondRequest.birthDate}</div>
          <div>Country of Birth: {bondRequest.countryOfBirth}</div>
          <div>Nationality: {bondRequest.nationality}</div>
          <div>
            Arrived on: {bondRequest.arrivalDate}, {arrivalMeans}
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <h3 className='mt-3'>Bond Contract (I-352)</h3>
      <div className='mb-4'>
        Please review, complete, and sign your Bond Contract (I-352). Click Next to move to the
        signature step. You can always get help at our support page (see footer below).
      </div>
      <div>
        <h6>Obligor</h6>
        <div className='mb-1'>{renderObligor()}</div>
        <ObligorEditModal
          obligor={obligor}
          onSave={(savedObligor) => handleSavedObligor(savedObligor)}
          buttonText='Edit'
          obligorEmail={ceBondsUser?.email}
        />
      </div>
      <hr />
      <div>
        <h6>Noncitizen</h6>
        <div>{renderNonCitizen()}</div>
      </div>
    </div>
  );
};

export default ObligorI352Summary;
