import { Link } from 'react-router-dom';
import { faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Stack } from 'react-bootstrap';
import { BondPayment, BondableRequest } from '../../models/bond-data';
import { BondStatusEnum } from '../../models/status';
import { BondStatusContent } from '../BondStatusContent';

export const UploadPaymentAction = ({
  bondRequest,
  bondPayment,
}: {
  bondRequest: BondableRequest;
  bondPayment: BondPayment | undefined;
}) => {
  if (bondRequest.statusCode.code === BondStatusEnum.I352_SIGNED_BY_OBLIGOR) {
    return (
      <>
        <ListGroup.Item variant='warning'>
          <Stack direction='horizontal'>
            <div>
              <FontAwesomeIcon className='pe-3 fs-2' icon={faExclamationCircle} color={'orange'} />
            </div>
            <div>
              <div>
                <span className='fw-bold'>Action Required: </span>
                <BondStatusContent bondStatusCode={bondRequest.statusCode.code} />
                {bondPayment && (
                  <div className='mt-3'>
                    <span className='fw-bold'>NOTE:</span> You need to upload your bond payment
                    receipt again to accompany a newly signed contract. This will replace the
                    previously uploaded receipt. If your payment succeeded the first time, you do
                    NOT need to make your payment again (only the receipt is needed again).
                  </div>
                )}
              </div>
              <div className='mt-2 mb-2'>
                <Link to='/bond-payment' state={bondRequest}>
                  <Button>
                    Bond Payment <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </Link>
              </div>
            </div>
          </Stack>
        </ListGroup.Item>
        <ListGroup.Item>
          <Stack direction='horizontal'>
            <div>
              <div>
                <small>
                  <div className='fw-bold'>Optional Actions:</div>
                  <div>If you need to make changes to your contract, use the button below.</div>
                  <Link to='/i352' state={bondRequest}>
                    <Button className='m-2' size='sm' variant='secondary'>
                      Re-Sign Contract (optional)
                    </Button>
                  </Link>
                </small>
              </div>
            </div>
          </Stack>
        </ListGroup.Item>
      </>
    );
  }

  return <></>;
};
