import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { BondStatus } from './BondStatus';
import { BondableRequest } from '../models/bond-data';
import SubjectSearchApi from '../api/SubjectSearchApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICeBondsSubject } from '../api/types';
import { useCeBondsUserContext } from '../contexts/UserContext';
import LoadingPage from './util/LoadingPage';
import { IApiError, useErrorContext } from '../contexts/ErrorContext';
import Utils from '../api/Utils';
import { statusesForActive } from '../models/status';

export const BondStatusContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ceBondsSubject = location.state as ICeBondsSubject;
  const ceBondsUser = useCeBondsUserContext();
  const [subjectBondRequests, setSubjectBondRequests] = useState<BondableRequest[]>();
  const error = useErrorContext();
  console.log('ceBondsSubject ', ceBondsSubject);

  useEffect(() => {
    console.log('Start search');
    if (ceBondsUser) {
      console.log('Inside search about to call API', ceBondsUser, ceBondsSubject);
      SubjectSearchApi.getBondsByANumber(ceBondsSubject.aNumber).then(
        (bondRequestList: BondableRequest[] | IApiError) => {
          if (Utils.isError(bondRequestList)) {
            const errorData = bondRequestList as IApiError;
            error.setError(errorData.message);
            setSubjectBondRequests([]);
          } else if ('length' in bondRequestList) {
            if (!hasActiveRequest(bondRequestList)) {
              navigate('/obligor', { state: ceBondsSubject });
            } else {
              setSubjectBondRequests(bondRequestList);
            }
          }
        },
      );
    }
  }, [ceBondsUser, ceBondsSubject]);

  const hasActiveRequest = (bondRequestList: BondableRequest[]) => {
    for (var bondRequestItem of bondRequestList) {
      if (statusesForActive.includes(bondRequestItem.statusCode.code)) {
        return true;
      }
    }

    return false;
  };

  if (!subjectBondRequests) {
    return <LoadingPage message={'Please Wait...'} />;
  }

  console.log('search result data is ', subjectBondRequests);
  return (
    <Container className='mt-3 mb-5'>
      {subjectBondRequests && <BondStatus bondRequestList={subjectBondRequests} />}
    </Container>
  );
};
