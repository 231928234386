import { useState } from 'react';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Spinner, Stack } from 'react-bootstrap';
import Utils from '../../api/Utils';
import ObligorDocumentApi from '../../api/ObligorDocumentApi';
import { IBondRequestDocument } from '../../api/types';
import BondDocUtil from '../../helper/BondDocUtil';

export const NoticeProgress = ({
  bondNoticeList,
}: {
  bondNoticeList: IBondRequestDocument[] | undefined;
}) => {
  const [docIdDownloading, setDocIdDownloading] = useState<number>(0);

  const handleDocDownload = (bondNotice: IBondRequestDocument) => {
    if (bondNotice) {
      console.log('downloading bond bondNotice doc...');
      setDocIdDownloading(bondNotice.bondReqDocId);
      ObligorDocumentApi.downloadBondRequestDoc(bondNotice.bondReqDocId).then((blob) => {
        var fileName = bondNotice.name;
        fileName = fileName.replace('.pdf_I323O', '.pdf'); // this is workaround for EARM file naming bug
        fileName = fileName.replace('.pdf_I391O', '.pdf'); // this is workaround for EARM file naming bug
        fileName = fileName.replace('.pdf_MTRO', '.pdf'); // this is workaround for EARM file naming bug
        Utils.downloadBlob(blob, fileName);
        setDocIdDownloading(0);
      });
    }
  };

  const showBondNotice = (bondNotice: IBondRequestDocument) => {
    if (!BondDocUtil.isNoticeActionNeeded(bondNotice)) {
      // Action is not needed. So show notice as action is complete.
      return showCompleteBondNotice(bondNotice);
    }

    if (bondNotice.bondReqDocId !== mostRecentNotice?.bondReqDocId) {
      // Action is needed, but this is not the last notice. So show notice as action is skipped
      return showSkippedBondNotice(bondNotice);
    }
  };

  const showCompleteBondNotice = (bondNotice: IBondRequestDocument) => {
    return (
      <ListGroup.Item className='align-items-center'>
        <Stack direction='horizontal'>
          <div>
            <FontAwesomeIcon className='pe-3 fs-3' icon={faCheckCircle} color={'green'} />
          </div>
          <div>
            <div>
              <div>{BondDocUtil.getNoticeText(bondNotice)} You have viewed this bond notice.</div>
              <div className='small'>
                Notice Date: {Utils.formatDisplayDate(bondNotice.createDate)}
              </div>
              {bondNotice.viewedDate && (
                <div className='small'>
                  Viewed Date: {Utils.formatDisplayDate(bondNotice.viewedDate)}
                </div>
              )}
              {bondNotice.ackedDate && (
                <div className='small'>
                  Acknowledged Date: {Utils.formatDisplayDate(bondNotice.ackedDate)}
                </div>
              )}
            </div>
            {bondNoticeDownload(bondNotice)}
          </div>
        </Stack>
      </ListGroup.Item>
    );
  };

  const showSkippedBondNotice = (bondNotice: IBondRequestDocument) => {
    return (
      <ListGroup.Item className='align-items-center'>
        <Stack direction='horizontal'>
          <div>
            <FontAwesomeIcon className='pe-3 fs-3' icon={faCheckCircle} color={'lightgray'} />
          </div>
          <div>
            <div>
              <div>
                {BondDocUtil.getNoticeText(bondNotice)} This notice, no longer needs to be viewed
                because it was replaced by a newer notice.
              </div>
              <div className='small'>
                Notice Date: ({Utils.formatDisplayDate(bondNotice.createDate)})
              </div>
            </div>
            {bondNoticeDownload(bondNotice)}
          </div>
        </Stack>
      </ListGroup.Item>
    );
  };

  const bondNoticeDownload = (bondNotice: IBondRequestDocument) => {
    return (
      <div className='small'>
        <Button className='ps-1' variant={'link'} onClick={() => handleDocDownload(bondNotice)}>
          <span className='small'>Download Notice</span>{' '}
          <FontAwesomeIcon className='ps-2' icon={faDownload} title='Download' />
        </Button>
        {docIdDownloading === bondNotice.bondReqDocId && (
          <span className='ps-3 small'>
            <Spinner animation='border' role='status' size='sm' />
            &nbsp; Please Wait...
          </span>
        )}
      </div>
    );
  };

  var mostRecentNotice = BondDocUtil.getMostRecentNotice(bondNoticeList);
  if (bondNoticeList) {
    return <>{bondNoticeList.map((bondNotice) => showBondNotice(bondNotice))}</>;
  }

  return <></>;
};
