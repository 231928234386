export enum BondStatusEnum {
  SUBJECT_NOT_BONDABLE = 'SUBJECT_NOT_BONDABLE',
  BOND_WITHDRAWN = 'BOND_WITHDRAWN',
  APPROVED_BONDABLE_REQUEST = 'APPROVED_BONDABLE_REQUEST',
  DENIED_BONDABLE_REQUEST = 'DENIED_BONDABLE_REQUEST',
  NEW_I352_REQUEST = 'NEW_I352_REQUEST', // UI controlled status
  I352_SIGNED_BY_OBLIGOR = 'I352_SIGNED_BY_OBLIGOR', // UI controlled status
  BOND_PAYMENT_UPLOADED = 'BOND_PAYMENT_UPLOADED', // UI controlled status
  SUBMITTED_I352_REQUEST = 'SUBMITTED_I352_REQUEST', // UI controlled status
  I352_CREATED_IN_EARM = 'I352_CREATED_IN_EARM',
  I352_VIEWED_BY_OFFICER = 'I352_VIEWED_BY_OFFICER',
  APPROVED_I352_REQUEST = 'APPROVED_I352_REQUEST',
  DENIED_I352_REQUEST = 'DENIED_I352_REQUEST',
  PARTIAL_I352_REQUEST = 'PARTIAL_I352_REQUEST', // Not used
  INVALID_I352 = 'INVALID_I352', // Used for wrong status in EARM
  NEW_BONDABLE_REQUEST = 'NEW_BONDABLE_REQUEST',
  NEW_BONDABLE_REQUEST_RECEIVED = 'NEW_BONDABLE_REQUEST_RECEIVED', // Not used
  NEW_BOND_REQUEST_VIEWED_BY_OFFICER = 'NEW_BOND_REQUEST_VIEWED_BY_OFFICER',
  NEW_BOND_REQUEST_RECEIVED_AFTER_HRS = 'NEW_BOND_REQUEST_RECEIVED_AFTER_HRS',
  SUBJECT_RELEASED = 'SUBJECT_RELEASED',
  I323_ADDED = 'I323_ADDED',
  I323_VIEWED = 'I323_VIEWED',
  I323_ACKED = 'I323_ACKED',
  I340_ADDED = 'I340_ADDED',
  I340_VIEWED = 'I340_VIEWED',
  I340_ACKED = 'I340_ACKED',
  I391_ADDED = 'I391_ADDED',
  I391_VIEWED = 'I391_VIEWED',
  I391_ACKED = 'I391_ACKED',
  MTR_ADDED = 'MTR_ADDED',
  MTR_VIEWED = 'MTR_VIEWED',
  MTR_ACKED = 'MTR_ACKED',
  MTG_ADDED = 'MTG_ADDED',
  MTG_VIEWED = 'MTG_VIEWED',
  MTG_ACKED = 'MTG_ACKED',
  REFUND_ADDR_CORRECT = 'REFUND_ADDR_CORRECT',
}

export const statusesForActionNeeded = [
  'APPROVED_BONDABLE_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'BOND_PAYMENT_UPLOADED',
  'I323_ADDED',
  'I323_VIEWED',
  'I340_ADDED',
  'I391_ADDED',
  'I391_VIEWED',
  'MTR_ADDED',
  'MTG_ADDED',
];

export const statusesForWithdraw = [
  'NEW_BONDABLE_REQUEST_RECEIVED',
  'NEW_BOND_REQUEST_VIEWED_BY_OFFICER',
  'NEW_BOND_REQUEST_RECEIVED_AFTER_HRS',
  'NEW_BONDABLE_REQUEST',
  'APPROVED_BONDABLE_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'BOND_PAYMENT_UPLOADED',
  'INVALID_I352',
  'DENIED_I352_REQUEST',
];

export const statusesForComments = [
  'APPROVED_BONDABLE_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'BOND_PAYMENT_UPLOADED',
  'APPROVED_I352_REQUEST',
  'I352_CREATED_IN_EARM',
  'SUBMITTED_I352_REQUEST',
  'NEW_I352_REQUEST',
  'PARTIAL_I352_REQUEST',
  'I352_VIEWED_BY_OFFICER',
  'DENIED_I352_REQUEST',
];

export const statusesForActive = [
  'NEW_BONDABLE_REQUEST',
  'APPROVED_BONDABLE_REQUEST',
  'NEW_I352_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'BOND_PAYMENT_UPLOADED',
  'SUBMITTED_I352_REQUEST',
  'I352_CREATED_IN_EARM',
  'I352_VIEWED_BY_OFFICER',
  'NEW_BONDABLE_REQUEST_RECEIVED',
  'NEW_BOND_REQUEST_VIEWED_BY_OFFICER',
  'NEW_BOND_REQUEST_RECEIVED_AFTER_HRS',
];

export const statusesForChanges = [
  'NEW_BONDABLE_REQUEST',
  'NEW_BONDABLE_REQUEST_RECEIVED',
  'NEW_BOND_REQUEST_RECEIVED_AFTER_HRS',
  'NEW_BOND_REQUEST_VIEWED_BY_OFFICER',
  'APPROVED_BONDABLE_REQUEST',
  'I352_SIGNED_BY_OBLIGOR',
  'BOND_PAYMENT_UPLOADED',
  'I352_CREATED_IN_EARM',
  'SUBMITTED_I352_REQUEST',
  'NEW_I352_REQUEST',
  'PARTIAL_I352_REQUEST',
  'I352_VIEWED_BY_OFFICER',
];
