import { useEffect, useState } from 'react';
import { faCheckCircle, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListGroup, Spinner, Stack } from 'react-bootstrap';
import { BondableRequest } from '../../models/bond-data';
import { statusesForChanges } from '../../models/status';
import Utils from '../../api/Utils';
import UploadObligorDocModal from '../UploadObligorDocModal';
import { IBondRequestDocument, IObligor } from '../../api/types';
import ObligorDocumentApi from '../../api/ObligorDocumentApi';
import ObligorApi from '../../api/ObligorApi';

export const RequestSubmittedProgress = ({
  bondObligorDoc,
  bondRequest,
}: {
  bondObligorDoc: IBondRequestDocument | undefined;
  bondRequest: BondableRequest;
}) => {
  const [obligor, setObligor] = useState<IObligor>();
  const [obligorDoc, setObligorDoc] = useState<IBondRequestDocument>();
  const [isObligorDocDownloading, setIsObligorDocDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (!obligorDoc) {
      setObligorDoc(bondObligorDoc); // save to state to use state var
    }
  }, [bondObligorDoc]);

  useEffect(() => {
    if (!obligor) {
      ObligorApi.getObligor()
        .then((cbo) => {
          if (cbo && cbo.obligorId) {
            setObligor(cbo);
          }
        })
        .catch((reason) => {
          console.error('get obligor error ', reason);
        });
    }
  }, []);

  const handleObligorDocDownload = () => {
    console.log('downloading obligor doc...');
    if (obligorDoc) {
      setIsObligorDocDownloading(true);
      ObligorDocumentApi.downloadBondRequestDoc(obligorDoc.bondReqDocId).then((blob) => {
        var docName = 'obligorIdDoc';
        if (obligorDoc) {
          docName = obligorDoc.name;
        }
        Utils.downloadBlob(blob, docName);
        setIsObligorDocDownloading(false);
      });
    }
  };

  const handleObligorDocUploaded = (uploadedDocument: IBondRequestDocument | undefined) => {
    setObligorDoc(uploadedDocument); // save to state so the page elements will update
  };

  return (
    <ListGroup.Item className='align-items-center'>
      <Stack direction='horizontal'>
        <div>
          <FontAwesomeIcon className='pe-3 fs-3' icon={faCheckCircle} color={'green'} />
        </div>
        <div>
          <div>
            You created this bond payment request.
            <span className='small ms-2'>({Utils.formatDisplayDate(bondRequest?.createDate)})</span>
          </div>
          {obligorDoc && (
            <div className='small'>
              <Button className='ps-1' variant={'link'} onClick={() => handleObligorDocDownload()}>
                <span className='small'>Download Identity</span>{' '}
                <FontAwesomeIcon className='ps-2' icon={faDownload} title='Download' />
              </Button>
              {statusesForChanges.includes(bondRequest.statusCode.code) && (
                <UploadObligorDocModal
                  onBondDocUploaded={(uploadedDocument) =>
                    handleObligorDocUploaded(uploadedDocument)
                  }
                  obligorTypeCode={obligor?.typeCode?.code}
                  bondRequestId={bondRequest.bondRequestId}
                  buttonText='Replace'
                />
              )}
              {isObligorDocDownloading && (
                <span className='ps-3'>
                  <Spinner animation='border' role='status' size='sm' />
                  &nbsp; Please Wait...
                </span>
              )}
            </div>
          )}
        </div>
      </Stack>
    </ListGroup.Item>
  );
};
